<template>
  <v-container fluid py-0 px-0>
    <invite-dialog
      ref="dialogInvite"
      :data="data"
      :user-list="userList"
      :role-list="roleList"
      :department-list="departmentList"
      :role-tooltip="roleTooltip"
      :show-sending="showSending"
      :check-max-code="checkMaxCode"
      @submit="inviteUser"
    />
    <import-error-dialog
      ref="dialogError"
      :role-list="roleList"
      :import-error="importError"
    />
    <base-dialog
      ref="dialogConfirm"
      :title="$t('message.confirm')"
      :message="'データを登録すると、自動で招待メールが送信されます。よろしいでしょうか？'"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'インポート'"
      @submit="checkImportData(false)"
    />
    <base-dialog
      ref="dialogWarning"
      :title="'注意'"
      :message="'データを登録すると、自動で招待メールが送信されます。よろしいでしょうか？'"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'インポートを続行'"
      @submit="checkImportData(true)"
    >
      <template v-slot:card-text>
        <div v-if="importError.duplicateEmails">
          既に登録/招待済みのユーザがいます。{{
            importError.duplicateEmails.join(', ')
          }}行目<br />
          インポートを続行する場合は,こちらのユーザにはメールを送信しません。
        </div>
      </template>
    </base-dialog>

    <div class="h-without-header">
      <v-row no-gutters>
        <v-col>
          <v-tabs
            v-model="active"
            align-with-title
          >
            <v-tab
              v-for="x in items"
              :key="x.id"
              ripple
              class="fs-12 px-3"
              @click="loadData"
            >
              {{ x.title }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="py-3">
          <v-btn
            color="primary"
            dark
            @click.stop="openInviteDialog()"
          >
            <s-icon icon="feather-plus" size="lg" class="tw-mr-1" />
            ユーザ招待
          </v-btn>
          <v-btn
            class="ml-2"
            @click.native.stop="selectCSV()"
          >
            一括インポート
          </v-btn>
          <input v-show="false" id="invitation-import-csv" type="file" />
          <v-btn
            v-tooltip="'インポート用のデータサンプルを取得'"
            class="ml-2"
            text
            @click="exportSampleData()"
          >
            {{ $t('message.accounting_get_sample') }}
          </v-btn>
        </v-col>

      </v-row>

      <v-tabs-items v-model="active">
        <v-tab-item v-for="y in items" :key="y.id" class="px-5">
          <div v-if="y.id == 1">
            <user-list
              :data="userList"
              :role-list="roleList"
              :department-list="departmentList"
              :has-owner-role="hasOwnerRole"
              :role-tooltip="roleTooltip"
            />
          </div>
          <div v-if="y.id == 2">
            <invite-list
              :data="data"
              :user-list="userList"
              :role-list="roleList"
              :department-list="departmentList"
              :has-owner-role="hasOwnerRole"
              :role-tooltip="roleTooltip"
              @load="loadData()"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import InviteList from './components/InviteList'
import UserList from './components/UserList'
import InviteDialog from '@/views/settings/components/user/components/InviteDialog'
import ImportErrorDialog from '@/views/settings/components/user/components/IｍportErrorDialog'
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import CSVReader from 'Helpers/csv'
import Encoding from 'encoding-japanese'

export default {
  components: {
    InviteList,
    UserList,
    InviteDialog,
    ImportErrorDialog
  },
  data() {
    return {
      active: null,
      data: [],
      roleList: [],
      userList: [],
      departmentList: [],
      showSending: false,
      csvData: null,
      items: [
        {
          id: 1,
          title: '登録済ユーザ',
        },
        {
          id: 2,
          title: '招待中',
        },
      ],
      roleTooltip:
        '＜標準権限＞<br>オーナー：フル権限 オーナー権限の編集可（オーナーのみ選択可能）<br>' +
        'システム管理者：フル権限<br>' +
        '経理管理者：システム設定不可<br>' +
        '経理マネージャ：システム・マスタ設定不可<br>' +
        'ログイン不可：メール取込可<br>' +
        'アップローダー：読み取りメニュー可<br>' +
        '回収担当者：回収メニュー可<br>' +
        '閲覧者：閲覧可<br>',
      importError: {
        ownerRoleErrorIndex: [],
        changeOwnerError: [],
        roleErrorIndex: [],
        emailErrorIndex: [],
        duplicateUserCode: [],
        duplicateEmails: [],
        nonExistDepartments: [],
      },
    }
  },
  computed: {
    hasOwnerRole: function () {
      return this.$store.getters.getUserRole == 1
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      try {
        this.data = []
        this.userList = []
        this.roleList = []
        let url = this.$store.getters.apiUserListFunction
        const auth_token = this.$store.getters.getAuthToken
        const user_role_id = this.$store.getters.getUserRole
        url +=
          '?organization_id=' +
          this.$store.getters.getOrganizationID +
          '&user_role_id=' +
          user_role_id
        axios
          .get(url, {
            headers: {
              Authorization: auth_token,
            },
          })
          .then((response) => {
            if (response.data.error) {
              this.$router.push('/invoice')
            } else {
              const data = response.data

              this.departmentList = data.department
              for (let i = 0; i < data.user_list.length; i++) {
                const userDepartment = data.user_list[i].department
                const toAdd = {
                  id: data.user_list[i].user.id,
                  user_organization_id: data.user_list[i].id,
                  full_name: data.user_list[i].user.full_name,
                  email: data.user_list[i].user.email,
                  code: data.user_list[i].code,
                  role_id: data.user_list[i].user_role.id,
                  role_code: data.user_list[i].user_role.code,
                  role_name: data.user_list[i].user_role.name,
                  department_id:
                    userDepartment !== null ? userDepartment.id : 0,
                  department_code:
                    userDepartment !== null ? userDepartment.code : 0,
                  department_name:
                    userDepartment !== null ? userDepartment.name : '未登録',
                  slack_status: data.slack_user_list[i],
                }
                this.userList.push(toAdd)
              }
              for (let i = 0; i < data.user_invite_list.length; i++) {
                const toAdd = {
                  id: data.user_invite_list[i].id,
                  email: data.user_invite_list[i].email,
                  valid_until: data.user_invite_list[i].valid_until,
                  user_role_id: data.user_invite_list[i].user_role.id,
                  user_role_code: data.user_invite_list[i].user_role.code,
                  user_role_name: data.user_invite_list[i].user_role.name,
                  invited_by:
                    data.user_invite_list[i].invited_by_user_organization.user
                      .email,
                  code: data.user_invite_list[i].user_organization_code,
                }
                this.data.push(toAdd)
              }

              // 権限によって表示内容変更
              data.user_role.forEach((role) => {
                const toAdd = {
                  id: role.id,
                  name: role.name,
                  code: role.code,
                }
                if (role.id == 1) {
                  if (user_role_id == 1) {
                    this.roleList.push(toAdd)
                  }
                } else if (role.id == 2) {
                  if (user_role_id == 1 || user_role_id == 2) {
                    this.roleList.push(toAdd)
                  }
                } else {
                  this.roleList.push(toAdd)
                }
              })
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    openInviteDialog() {
      this.$refs.dialogInvite.open()
    },
    inviteUser(formInvite){
      this.showSending = true
      const auth_token = this.$store.getters.getAuthToken
      const apiUrl = this.$store.getters.apiUserInviteFunction
      const org_id = this.$store.getters.getOrganizationID
      const user_org_id = this.$store.getters.getUserOrganizationID
      let hostUrl =
        process.env.VUE_APP_HOSTURL_EMAIL + 'session/invite/confirm/' //inviteConfirm.vue

      const data = {
        email: formInvite.email,
        user_role_id: formInvite.selectedRole,
        department_id: formInvite.selectedDepartment,
        code: formInvite.inputCode,
        organization_id: org_id,
        user_organization_id: user_org_id,
        auth_token: auth_token,
        url: hostUrl,
      }
      this.$store.dispatch('addInvite', {
        data,
        apiUrl,
      }).then((response) => {
        if (response.data.status == 'success') {
          this.$refs.dialogInvite.close()
          Snackbar.success(this.$t('message.users_invite_sent'))

          const userInvited = response.data.data
          const userSendInvitation = userInvited.user_send_invitation

          var toAdd = {
            id: userInvited.id,
            code: userInvited.user_organization_code,
            email: userInvited.email,
            user_role_id: userInvited.user_role.id,
            user_role_code: userInvited.user_role.code,
            user_role_name: userInvited.user_role.name,
            valid_until: userInvited.valid_until,
            invited_by: userSendInvitation.email,
          }
          this.data.push(toAdd)
          this.active = 1
        }
        this.showSending = false
      })
    },
    selectCSV() {
      CSVReader.read('invitation-import-csv')
        .then((csvData) => {
          this.csvData = csvData
          this.$refs.dialogConfirm.open()
        })
        .catch((e) => console.log(e))
    },
    async checkImportData(force_import = false) {
      try {
        this.$refs.dialogConfirm.close()
        this.$refs.dialogWarning.close()
        const url = this.$store.getters.apiInvitationBulkCreate
        const headers = { Authorization: this.$store.getters.getAuthToken }
        if (this.checkColumnsEmpty()) {
          this.$refs.dialogError.open()
          return
        }
        await this.numberingUserCode()
        const data = this.csvData
        const user_organization_id = this.$store.getters.getUserOrganizationID
        const organization_id = this.$store.getters.getOrganizationID
        const hostUrl =
          process.env.VUE_APP_HOSTURL_EMAIL + 'session/invite/confirm/'
        Snackbar.warning('インポートには少々お時間をいただく場合がございます')
        axios
          .post(
            url,
            {
              data,
              user_organization_id,
              organization_id,
              force_import,
              hostUrl,
            },
            { headers }
          )
          .then((response) => {
            if (response.data.update_owner_role_error) {
              this.$refs.dialogError.open()
              this.importError.ownerRoleErrorIndex =
                response.data.update_owner_role_error
            } else if (response.data.change_owner_error) {
              this.$refs.dialogError.open()
              this.importError.changeOwnerError =
                response.data.change_owner_error
            } else if (response.data.role_exists_error) {
              this.$refs.dialogError.open()
              this.importError.roleErrorIndex = response.data.role_exists_error
            } else if (response.data.non_exist_departments) {
              this.$refs.dialogError.open()
              this.importError.nonExistDepartments =
                response.data.non_exist_departments
            } else if (response.data.duplicate_user_code) {
              this.$refs.dialogError.open()
              this.importError.duplicateUserCode =
                response.data.duplicate_user_code
            } else if (response.data.warning) {
              let result = response.data
              this.$refs.dialogWarning.open()
              this.importError.duplicateEmails = result.duplicate_emails
              this.csvData = result.data
              this.csvData.unshift(['', '', '', ''])
            } else {
              Snackbar.success('インポートが完了しました')
              this.loadData()
              this.active = 1
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    checkColumnsEmpty() {
      //validate mail role
      let dialogError = false
      this.importError.roleErrorIndex.length = 0
      this.importError.ownerRoleErrorIndex.length = 0
      this.importError.changeOwnerError.length = 0
      this.importError.nonExistDepartments.length = 0
      this.importError.duplicateUserCode.length = 0
      this.importError.emailErrorIndex.length = 0
      let userRoleObject = {}
      this.roleList.forEach((role) => {
        userRoleObject[role.name] = role.id
      })
      this.csvData.forEach((row, i) => {
        if (i === 0) {
          return
        }
        if (row[0] === '' || row[0].indexOf('@') === -1) {
          //email is empty or invaild format
          dialogError = true
          this.importError.emailErrorIndex.push(i + 1)
        }
        let userRole = !isNaN(row[2]) ? row[2] : userRoleObject[row[2]]
        if (!userRole) {
          let userRoleRow = row[2].replace(/"/g, '')
          userRoleRow = userRoleRow.replace(/'/g, '')
          userRoleRow = Number(userRoleRow)
          row[2] = userRoleRow
          userRole = !isNaN(userRoleRow)
            ? userRoleRow
            : userRoleObject[userRoleRow]
        }
        if (!row[2] || !userRole) {
          dialogError = true
          this.importError.roleErrorIndex.push(i + 1)
        } else {
          row[2] = userRole.toString()
        }
      })
      return dialogError
    },
    async numberingUserCode() {
      const blankCodeRowList = []
      const csvDataCode = []
      for (const [index, data] of this.csvData.entries()) {
        if (index === 0) {
          continue
        }
        if (data[1].replace(/[\s"]+/g, '') === '') {
          blankCodeRowList.push(index)
        } else {
          csvDataCode.push(data[1])
        }
      }
      if (blankCodeRowList.length) {
        const props = {
          message:
            'コードが空のデータにはコードを自動で付与します。よろしいですか?',
          confirmShowDialog: true,
          localStorageSetName: 'confirmMasterCodeAutomatically',
        }
        const yes = await this.$confirm(props)
        if (!yes) {
          return
        }
        const list = this.userList.concat(this.data)
        let maxCode = this.checkMaxCode(list)
        for (const row of blankCodeRowList) {
          maxCode = String(Number(maxCode) + 1)
          while (csvDataCode.includes(maxCode)) {
            maxCode = String(Number(maxCode) + 1)
          }
          this.csvData[row][1] = maxCode
        }
      }
    },
    checkMaxCode(data, startValue = '1000') {
      let maxCode = startValue
      const dataCodeList = []
      for (const item of data) {
        if (!isNaN(item.code)) {
          dataCodeList.push(item.code)
        }
      }
      if (dataCodeList.length > 0) {
        maxCode = Math.max(...dataCodeList)
      }
      return maxCode
    },
    exportSampleData() {
      try {
        let unicodeList = this.createSampleData()

        const shiftJisCodeList = Encoding.convert(
          unicodeList,
          'sjis',
          'unicode'
        )
        const uInt8List = new Uint8Array(shiftJisCodeList)
        let blob = new Blob([uInt8List], { type: 'text/csv' })
        let a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = 'invitation_sample.csv'
        a.click()
      } catch (e) {
        console.log(e)
      }
    },
    createSampleData() {
      const csvFormat = (col) => {
        return `"${col}"`
      }
      let headers = ['メールアドレス', 'コード', '権限', '所属部門']
      let csvDataSet = [headers.map(csvFormat).join(',')]

      for (let user of this.userList) {
        let departmentCode = ''
        if (user.department_code) {
          departmentCode = user.department_code
        }
        let row = [user.email, user.code, user.role_code, departmentCode]
        csvDataSet.push(row.map(csvFormat).join(','))
      }
      const csvData = csvDataSet.join('\n')

      const unicodeList = []
      for (let i = 0; i < csvData.length; i += 1) {
        unicodeList.push(csvData.charCodeAt(i))
      }
      return unicodeList
    },
  },
}
</script>
