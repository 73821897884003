var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('app-section-loader',{attrs:{"status":_vm.loader}}),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialogOption),callback:function ($$v) {_vm.dialogOption=$$v},expression:"dialogOption"}},[(_vm.deleteInvitation)?_c('v-card',{staticClass:"sweeep-dialog"},[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.$t('message.confirm'))+"\n      ")]),_c('v-card-text',[_vm._v("\n        "+_vm._s(_vm.$t('message.user_modal_cancel_invitation'))+"\n      ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{domProps:{"textContent":_vm._s(_vm.$t('message.modal_cancel_btn'))},nativeOn:{"click":function($event){_vm.dialogOption = false}}}),_c('v-btn',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('message.user_modal_delete'))},on:{"click":function($event){_vm.deleteUser()
            _vm.dialogOption = false}}})],1)],1):_c('v-card',{staticClass:"sweeep-dialog"},[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.$t('message.confirm'))+"\n      ")]),_c('v-card-text',[_vm._v("\n        ユーザにメールを再送信してもよろしいですか？\n      ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{domProps:{"textContent":_vm._s(_vm.$t('message.modal_cancel_btn'))},nativeOn:{"click":function($event){_vm.dialogOption = false}}}),_c('v-btn',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('送信')},on:{"click":function($event){_vm.resendInvitationEmail()
            _vm.dialogOption = false}}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"my-2",attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"item-key":"id","footer-props":_vm.footerProps,"fixed-header":"","height":"calc(100vh - 300px)"},scopedSlots:_vm._u([{key:"item.valid_until",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.getValidUntilFormated(item.valid_until))+"\n      ")]}},{key:"item.user_role_name",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.user_role_name)+":\n        "+_vm._s(!item.user_role_code ? 'コードなし' : item.user_role_code)+"\n      ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"color":"default","small":"","rounded":""},on:{"click":function($event){return _vm.selectUser(item, true)}}},[_vm._v("\n            "+_vm._s(_vm.$t('message.user_table_cancel'))+"\n          ")]),_c('v-btn',{attrs:{"color":"primary","small":"","rounded":""},on:{"click":function($event){return _vm.selectUser(item, false)}}},[_vm._v("\n            招待メール再送信\n          ")])],1)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"info","icon":"warning"}},[_vm._v("\n          "+_vm._s(_vm.$t('message.noResult'))+"\n        ")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }