<template>
  <v-card flat class="pa-3">
    <app-section-loader :status="loader" />

    <base-dialog
      ref="dialogDelete"
      :title="$t('message.delete')"
      :message="$t('message.user_modal_delete_user')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.user_modal_delete')"
      :loading="loadingDelete"
      @submit="deleteUser()"
    />

    <base-dialog
      ref="dialogEdit"
      title="ユーザ情報編集"
      :message="$t('message.user_modal_delete_user')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.user_modal_edit')"
      @submit="
        editUserInfo()
        $refs.dialogEdit.close()
      "
    >
      <template v-slot:card-text>
        <v-layout pa-4>
          <v-text-field
            v-model="editForm.inputCode"
            :label="$t('message.user_code')"
            placeholder=" "
            :rules="inputCodeRules"
            required
            outlined
            dense
            filled
          />
        </v-layout>
        <v-layout v-if="showEditRole" px-4 py-2>
          <!--v-if userRole!==1  -->
          <v-autocomplete
            v-model="editForm.selectedRole"
            v-tooltip="roleTooltip"
            label="権限"
            placeholder="選択してください"
            :items="roleList"
            item-text="name"
            item-value="id"
            required
            outlined
            dense
            filled
          />
        </v-layout>
        <v-layout px-4 py-2>
          <v-autocomplete
            v-model="editForm.selectedDepartment"
            label="所属部門"
            placeholder="選択してください"
            :items="departmentList"
            item-text="name_code"
            item-value="id"
            required
            outlined
            dense
            filled
          />
        </v-layout>
      </template>
    </base-dialog>

    <base-dialog
      ref="dialogTokenConfirm"
      :title="'Slackトークン発行'"
      :message="'トークン未発行のユーザにSlackとの提携を開始するためのトークンをEmailで一斉送信します。\nよろしいですか？\n提携したユーザのみSlack上から請求書のアップロードが行えます。'"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'発行'"
      :loading="loadingIssueToken"
      @submit="slackTokenBulkCreate()"
    />

    <base-dialog
      ref="dialogIssueTokenOne"
      :title="'Slackトークン発行'"
      :message="'選択したユーザにSlackとの提携を開始するためのトークンをEmailで送信します。\nよろしいですか？\n提携したユーザのみSlack上から請求書のアップロードが行えます。'"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'発行'"
      :loading="loadingIssueToken"
      @submit="issueSlackTokenOne()"
    />

    <base-dialog
      ref="dialogSuspendSlack"
      :title="'Slack提携停止'"
      :message="'選択したユーザのSlack利用提携を停止します。よろしいですか?'"
      :cancel-text="$t('message.no')"
      :submit-text="$t('message.yes')"
      @submit="suspendSlack()"
    />

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-text-field
            v-model="search"
            placeholder="検索"
            filled
            dense
            rounded
            clearable
            clear-icon="feather-x-circle"
            hide-details
          />
          <v-spacer />

          <v-btn
            v-tooltip="'Slack利用トークンを一斉に発行します'"
            color="primary"
            style="text-transform: none"
            @click="$refs.dialogTokenConfirm.open()"
          >
            Slackトークン一括発行
          </v-btn>
        </v-row>
      </v-container>

      <v-data-table
        v-model="selected"
        class="my-2"
        :headers="headers"
        :items="data"
        :search="search"
        item-key="id"
        :footer-props="footerProps"
        fixed-header
        height="calc(100vh - 300px)"
      >
        <template #item.role_name="{ item }">
          {{ item.role_name }}:
          {{ !item.role_code ? 'コードなし' : item.role_code }}
        </template>

        <template #item.department_name="{ item }">
          {{ item.department_name }}:
          {{ !item.department_code ? 'コードなし' : item.department_code }}
        </template>

        <template #item.status="{ item }">
          <v-btn
            v-if="item.slack_status"
            v-tooltip="'Slack利用トークンを再発行します'"
            color="primary"
            small
            rounded
            class="my-1"
            @click="
              selectUser(item)
              $refs.dialogIssueTokenOne.open()
              resend = true
            "
          >
            トークン再発行
          </v-btn>
          <v-btn
            v-else
            v-tooltip="'Slack利用トークンを個別に発行します'"
            color="primary"
            small
            rounded
            class="my-1"
            @click="
              selectUser(item)
              $refs.dialogIssueTokenOne.open()
              resend = false
            "
          >
            トークン発行
          </v-btn>
          <v-btn
            v-if="item.slack_status && item.role_id != 1"
            v-tooltip="'選択ユーザのSlack利用を停止します'"
            color="error"
            small
            rounded
            class="my-1"
            @click="
              selectUser(item)
              $refs.dialogSuspendSlack.open()
            "
          >
            提携停止
          </v-btn>
        </template>

        <template #item.action="{ item }">
          <div class="d-flex justify-space-around">
            <v-btn
              color="primary"
              small
              rounded
              @click.native.stop="$refs.dialogEdit.open()"
              @click="selectEditUser(item)"
            >
              編集
            </v-btn>
            <v-btn
              v-if="checkEnableToDelete(item)"
              small
              rounded
              @click.native.stop="$refs.dialogDelete.open()"
              @click="selectUser(item)"
            >
              {{ $t('message.table_delete_btn') }}
            </v-btn>
          </div>
        </template>

        <template #no-results>
          <v-alert :value="true" color="info" icon="warning">
            {{ $t('message.noResult') }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AppConfig from 'Constants/AppConfig'
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import { getCurrentAppLayout } from 'Helpers/helpers'

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    roleList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    hasOwnerRole: {
      type: Boolean,
      default: false,
    },
    roleTooltip: {
      type: String,
      required: true,
    },
  },
  data() {
    let t = this
    return {
      user_id: '',
      user_role_id: '',
      showSending: false,
      userRole: ['管理者', '一般社員'],
      valid: false,
      valid_edit: false,
      loader: false,
      search: '',
      selected: [],
      headers: [],
      items: [],
      input_tax_rate: '',
      input_tax_name: '',
      inputRules: [(v) => !!v || '※この項目は必須です'],
      inputRulesNumber: [
        (v) => !!v || '※この項目は必須です（数字のみ入力可能です）',
      ],
      inputCodeRules: [
        (v) => v.length <= 50 || '50' + t.$t('message.character_max_length'),
      ],
      selectedUser: [],
      selected_user_index: '',
      input_tax_name_edit: '',
      input_tax_rate_edit: '',
      loadingDelete: false,
      loadingIssueToken: false,
      form1: {
        valid: false,
        name: '',
        nameRules: [
          (v) => !!v || '必ず入力ください',
          (v) => v.length <= 20 || '20' + t.$t('message.character_max_length'),
        ],
        email: '',
        emailRules: [
          (v) => !!v || '必ず入力ください',
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              v
            ) || '正しい形式で入力ください',
        ],
      },
      footerProps: {
        'items-per-page-text': '表示件数',
        'items-per-page-options': AppConfig.pagination,
      },
      pagination: AppConfig.pagination,
      showEditRole: true,
      editForm: {
        selectedRole: '',
        selectedDepartment: '',
        selectedUserOrgId: '',
        selected_user_index: '',
        inputCode: '',
      },
      resend: false,
    }
  },
  mounted() {
    try {
      var t = this
      this.user_id = this.$store.getters.getUserID
      this.user_role_id = this.$store.getters.getUserRole

      this.headers = [
        { text: 'コード', value: 'code' },
        { text: t.$t('message.user_table_full_name'), value: 'full_name' },
        { text: t.$t('message.user_table_email'), value: 'email' },
        { text: t.$t('message.user_table_role_name'), value: 'role_name' },
        {
          text: t.$t('message.user_table_department'),
          value: 'department_name',
        },
        {
          width: 130,
          text: 'Slack提携',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        { width: 160, text: '', value: 'action', sortable: false },
      ]
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    deleteUser() {
      try {
        const data = {
          id: this.selectedUser.id,
        }
        this.loadingDelete = true
        this.$store
          .dispatch('deleteUser', data)
          .then(() => {
            this.loadingDelete = false
            this.$refs.dialogDelete.close()
            this.data.splice(this.selected_user_index, 1)
            Snackbar.success(this.$t('message.success'))
          })
          .catch((error) => {
            this.loadingDelete = false
            this.$refs.dialogDelete.close()
            Snackbar.error(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    selectUser(data) {
      try {
        this.selectedUser = data
        this.selected_user_index = this.data.findIndex((x) => x.id == data.id)
        this.input_tax_name_edit = data.name
        this.input_tax_rate_edit = data.rate
      } catch (e) {
        console.log(e)
      }
    },
    submitnewInvite() {
      try {
        if (this.$refs.form_invite.validate()) {
          this.showSending = true
          const auth_token = this.$store.getters.getAuthToken
          const apiUrl = this.$store.getters.apiUserInviteFunction
          const org_id = this.$store.getters.getOrganizationID
          const user_org_id = this.$store.getters.getUserOrganizationID
          let hostUrl =
            process.env.VUE_APP_HOSTURL_EMAIL + 'session/invite/confirm/'

          const data = {
            email: this.form1.email,
            user_role_id: this.form1.select,
            organization_id: org_id,
            user_organization_id: user_org_id,
            auth_token: auth_token,
            url: hostUrl,
          }
          this.$store
            .dispatch('addInvite', {
              data,
              apiUrl,
            })
            .then((response) => {
              if (response.data.status == 'success') {
                Snackbar.success(this.$t('message.users_invite_sent'))
                this.showSending = false
                this.$refs.dialog.close()
                this.$refs.form_invite.reset()
                var toAdd = {
                  id: response.data.data.id,
                  email: response.data.data.email,
                  user_role_id: response.data.data.user_role.id,
                  user_role_name: response.data.data.user_role.name,
                  valid_until: response.data.data.valid_until,
                  invited_by:
                    response.data.data.invited_by_user_organization.user.email,
                }
                this.data.push(toAdd)
              }
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    getCurrentAppLayoutHandler() {
      return getCurrentAppLayout(this.$router)
    },
    selectEditUser(item) {
      try {
        this.editForm.selectedRole = item.role_id
        this.editForm.selectedDepartment = item.department_id
        this.editForm.inputCode = item.code
        this.editForm.selectUserIndex = this.data.findIndex(
          (x) => x.id == item.id
        )
        this.editForm.selectedUserOrgId = item.user_organization_id
        if (item.role_id == 1) {
          this.showEditRole = this.hasOwnerRole ? true : false
        } else {
          this.showEditRole = true
        }
      } catch (e) {
        console.log(e)
      }
    },
    editUserInfo() {
      try {
        const ownUserID = this.$store.getters.getUserOrganizationID
        const editUserID = this.editForm.selectedUserOrgId
        const editOwnUser = ownUserID == editUserID
        let data = {
          userOrganizationId: editUserID,
          department: this.editForm.selectedDepartment,
          userOrganizationCode: this.editForm.inputCode,
          userRole: this.editForm.selectedRole,
        }
        this.$store
          .dispatch('updateUser', data)
          .then((responseData) => {
            let editedUser = this.data[this.editForm.selectUserIndex]
            editedUser.department_id = responseData.department_id
            editedUser.department_name = responseData.department_name
            editedUser.department_code = responseData.department_code
            editedUser.role_name = responseData.user_role_name
            editedUser.role_code = responseData.user_role_code
            editedUser.role_id = responseData.user_role_id
            editedUser.code = responseData.code
            Snackbar.success('更新しました。')
            if (editOwnUser) {
              this.$store.commit('setUserRole', this.editForm.selectedRole)
            }
          })
          .catch((error) => {
            Snackbar.error(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    slackTokenBulkCreate() {
      try {
        this.loadingIssueToken = true
        const user_organization_id = this.$store.getters.getUserOrganizationID
        const organization_id = this.$store.getters.getOrganizationID
        const mode = 'broadcast'
        axios
          .put(
            this.$store.getters.apiSlackAuth,
            {
              user_organization_id,
              organization_id,
              mode,
            },
            { headers: { Authorization: this.$store.getters.getAuthToken } }
          )
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
            } else {
              Snackbar.success('成功しました')
              this.data.forEach((v) => {
                v.slack_status = true
              })
            }
            this.loadingIssueToken = false
            this.$refs.dialogTokenConfirm.close()
          })
      } catch (e) {
        console.log(e)
      }
    },
    suspendSlack() {
      const url = this.$store.getters.apiSlackAuth
      axios
        .delete(url, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { user_org_id: this.selectedUser.user_organization_id },
        })
        .then((response) => {
          if (response.data.status == 'success') {
            Snackbar.success('成功しました')
            this.selectedUser.slack_status = false
          } else {
            Snackbar.error('エラーが発生しました。再度お試しください。')
          }
          this.$refs.dialogSuspendSlack.close()
        })
    },
    issueSlackTokenOne() {
      this.loadingIssueToken = true
      const user_organization_id = this.selectedUser.user_organization_id
      const organization_id = this.$store.getters.getOrganizationID
      const mode = this.resend ? 'resend' : 'initial'
      axios
        .put(
          this.$store.getters.apiSlackAuth,
          {
            user_organization_id,
            organization_id,
            mode,
          },
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        )
        .then((response) => {
          this.loadingIssueToken = false
          this.$refs.dialogIssueTokenOne.close()
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            Snackbar.success('成功しました')
            this.selectedUser.slack_status = true
          }
        })
    },
    checkEnableToDelete(item) {
      const userOrgId = this.$store.getters.getUserOrganizationID
      const targetUserOrgId = item.user_organization_id
      if (this.hasOwnerRole) {
        return targetUserOrgId != userOrgId
      } else {
        return item.role_id != 1 && targetUserOrgId != userOrgId
      }
    },
  },
}
</script>
