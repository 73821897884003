import { render, staticRenderFns } from "./DefaultJournal.vue?vue&type=template&id=8b031202&scoped=true&"
import script from "./DefaultJournal.vue?vue&type=script&lang=js&"
export * from "./DefaultJournal.vue?vue&type=script&lang=js&"
import style0 from "./DefaultJournal.vue?vue&type=style&index=0&id=8b031202&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b031202",
  null
  
)

export default component.exports