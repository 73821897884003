<template>
  <v-dialog v-model="dialog" max-width="650">
    <v-card class="sweeep-dialog">
      <v-card-title>インポートエラー</v-card-title>
      <v-card-text v-if="importError.ownerRoleErrorIndex.length">
        オーナーの権限は指定できません<br />
        CSV行数 {{ importError.ownerRoleErrorIndex.join(', ') }}
      </v-card-text>
      <v-card-text v-if="importError.changeOwnerError.length">
        オーナー権限は変更できません<br />
        CSV行数 {{ importError.changeOwnerError.join(', ') }}
      </v-card-text>
      <v-card-text v-if="importError.roleErrorIndex.length">
        <div>
          利用できない権限が指定されています。利用可能な権限番号のみを記入ください。（CSV行番号：{{
            importError.roleErrorIndex.join(', ')
          }}）
        </div>
        <div class="mt-3">＜利用可能な権限＞</div>
        <ul>
          <template v-for="(role, index) in roleList">
            <div :key="index">{{ role.code }}：{{ role.name }}</div>
          </template>
        </ul>
        <div class="mt-3">＊オーナー権限はCSV取込みできません。</div>
      </v-card-text>
      <v-card-text v-if="importError.emailErrorIndex.length">
        メールアドレスが空白もしくは不正な形式です。再度ご確認ください<br />
        CSV行数 {{ importError.emailErrorIndex.join(', ') }}
      </v-card-text>
      <v-card-text v-if="importError.nonExistDepartments.length">
        利用できない部門が指定されています。<br />
        所属部門には部門マスタに登録済みの部門コードを指定してください。（CSV行番号：{{
          formatWarningMessage(importError.nonExistDepartments)
        }}）
      </v-card-text>
      <v-card-text v-if="importError.duplicateUserCode.length">
        ユーザコードが重複しています。<br />
        (CSV行番号：{{ formatWarningMessage(importError.duplicateUserCode) }})
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click.native="dialog = false"
          v-text="$t('message.modal_cancel_btn')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    roleList: {
      type: Array,
      required: true,
    },
    importError: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    open(){
      this.dialog = true
    },
    close(){
      this.dialog = false
    },
    formatWarningMessage(array) {
      array = array.map((v) => {
        return `${v[0]}行目: ${v[1]}`
      })
      return array.join(', ')
    },
  }
}

</script>