<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    scrollable
    max-width="450px"
  >
    <v-card class="sweeep-dialog">
      <v-card-title> ユーザ招待 </v-card-title>
      <v-card-text>
        <v-form ref="formInviteRef" v-model="formInvite.valid">
          <v-text-field
            v-model="formInvite.email"
            label="メールアドレス"
            placeholder=" "
            :rules="formInvite.emailRules"
            required
            dense
            outlined
            filled
          />
          <v-select
            v-model="formInvite.selectedRole"
            v-tooltip="roleTooltip"
            label="権限"
            placeholder="選択してください"
            :items="roleList"
            :rules="[(v) => !!v || '必ず入力ください']"
            item-text="name"
            item-value="id"
            required
            dense
            outlined
            filled
          />
          <v-select
            v-model="formInvite.selectedDepartment"
            label="所属部門"
            placeholder="選択してください"
            :items="departmentList"
            item-text="name"
            item-value="id"
            dense
            outlined
            filled
          />
          <v-text-field
            v-model="formInvite.inputCode"
            label="ユーザコード"
            placeholder=" "
            :rules="[
              (v) =>
                !v ||
                v.replace(/^[ 　]*/, '').length !== 0 ||
                'スペースのみの登録はできません',
              (v) => v.length < 51 || '50字以内で入力してください',
            ]"
            dense
            outlined
            filled
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click.native="dialog = false"
          v-text="$t('message.modal_cancel_btn')"
        />
        <v-btn
          v-show="showSending"
          disabled=""
          v-text="$t('message.user_sending_invitation')"
        />
        <v-btn
          v-show="!showSending"
          color="primary"
          @click.native="submitnewInvite"
          v-text="$t('message.invite')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    roleList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    roleTooltip: {
      type: String,
      required: true,
    },
    showSending: {
      type: Boolean,
      default: false
    },
    checkMaxCode: {
      type: Function,
      required: true
    }
  },
  data() {
    const t = this
    return {
      valid: false,
      dialog: false,
      selected: [],
      items: [],
      formInvite: {
        valid: false,
        nameRules: [
          (v) => !!v || '必ず入力ください',
          (v) => v.length <= 20 || '20' + t.$t('message.character_max_length'),
        ],
        email: '',
        emailRules: [
          (v) => !!v || '必ず入力ください',
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              v
            ) || '正しい形式で入力ください',
          (v) => v.length <= 50 || '50' + t.$t('message.character_max_length'),
        ],
        selectedDepartment: '',
        selectedRole: '',
        inputCode: '',
      },
    }
  },
  methods: {
    open(){
      this.dialog = true
      this.$nextTick(() => {
        this.resetInviteForm()
      })
    },
    close(){
      this.dialog = false
    },
    async submitnewInvite() {
      try {
        if (this.$refs.formInviteRef.validate()) {
          if (!this.formInvite.inputCode) {
            const props = {
              message: 'ユーザコードを自動で付与します。よろしいですか?',
              confirmShowDialog: true,
              localStorageSetName: 'confirmUserCodeAutomatically',
            }
            const yes = await this.$confirm(props)
            if (!yes) {
              return
            }
            const list = this.userList.concat(this.data)
            const maxCode = this.checkMaxCode(list)
            this.formInvite.inputCode = String(Number(maxCode) + 1)
          }
          this.$emit('submit', this.formInvite)
        }
      } catch (e) {
        console.log(e)
      }
    },
    resetInviteForm() {
      this.formInvite.email = ''
      this.formInvite.selectedDepartment = ''
      this.formInvite.selectedRole = ''
      this.formInvite.inputCode = ''
      this.$refs.formInviteRef.resetValidation()
    },
  },
}
</script>
