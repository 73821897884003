<template>
  <v-card outlined tile class="w-100">
    <vue-perfect-scrollbar>
      <v-card-text class="h-without-header-footer">
        <v-form
          ref="form"
          v-model="form.valid"
          class="pa-4 mb-5"
          lazy-validation
          style="width: 600px"
        >
          <v-card class="sweeep-dialog">
            <v-card-title> 会社情報 </v-card-title>
            <v-card-text class="pa-4">
              <v-container>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-text-field
                      v-model="form.company_name"
                      label="会社名 *"
                      hint="例: sweeep株式会社"
                      placeholder=" "
                      :rules="form.nameRules"
                      class="required"
                      required
                      dense
                      outlined
                      filled
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pl-0 py-0">
                    <v-text-field
                      :value="
                        phoneNumberWithhyphen(form.contact_number_list[0])
                      "
                      label="電話番号"
                      hint="例: 03-0000-0000"
                      placeholder=" "
                      :rules="form.inputRules"
                      class=""
                      dense
                      outlined
                      filled
                      @input="removeHyphen($event, 0)"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pa-0">
                    <v-text-field
                      v-model="form.address_list[0][0]"
                      v-mask="'###-####'"
                      label="郵便番号"
                      hint="例: 100-0004"
                      placeholder=" "
                      validate-on-blur
                      class=""
                      dense
                      outlined
                      filled
                    />
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-text-field
                      v-model="form.address_list[0][1]"
                      label="住所"
                      hint="例: 東京都千代田区大手町1-6-1"
                      placeholder=" "
                      :rules="form.inputRules"
                      class=""
                      dense
                      outlined
                      filled
                    />
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-select
                      v-model="form.industry_id"
                      label="業種"
                      placeholder=" "
                      :items="form.items_industry_list"
                      item-text="name"
                      item-value="id"
                      validate-on-blur
                      dense
                      outlined
                      filled
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <v-card class="sweeep-dialog mt-4">
            <v-card-title>
              プロフィールを追加（読取精度をアップ）
              <s-icon
                tooltip="追加されたプロフィール情報は読み取り結果から除外されるため精度向上に寄与します。"
                class="tw-ml-2"
                icon="feather-info"
              />
            </v-card-title>
            <v-card-text class="pa-4">
              <div class="fw-bold mb-3">
                会社名を追加
                <v-btn
                  v-tooltip="
                    '会社名を追加する事で請求元の会社名を正確に読み取る事ができます'
                  "
                  icon
                  x-small
                  color="primary"
                  @click="addCompanyForm"
                >
                  <s-icon icon="feather-plus" color="current" size="xl" />
                </v-btn>
                <v-btn
                  v-show="form.excludeCompanyName.length > 0"
                  class="ml-2"
                  small
                  @click="form.excludeCompanyName.pop()"
                >
                  削除
                </v-btn>
              </div>
              <div class="mb-4">
                <template v-for="(item, i) in form.excludeCompanyName">
                  <v-text-field
                    :key="i"
                    v-model="form.excludeCompanyName[i]"
                    label="会社名"
                    dense
                    outlined
                    filled
                    :rules="form.nameRules"
                    hide-details
                    class="mt-2"
                  />
                </template>
              </div>

              <v-divider />
              <div class="fw-bold my-3">
                電話番号を追加
                <v-btn icon x-small color="primary" @click="addPhoneNumberForm">
                  <s-icon icon="feather-plus" color="current" size="xl" />
                </v-btn>
                <v-btn
                  v-show="form.contact_number_list.length > 1"
                  class="ml-2"
                  color=""
                  small
                  @click="popPhoneNumberForm"
                >
                  削除
                </v-btn>
              </div>
              <div class="mb-4">
                <template v-for="(item, i) in form.contact_number_list">
                  <v-layout v-show="i > 0" :key="i">
                    <v-flex xs12>
                      <v-text-field
                        :value="
                          phoneNumberWithhyphen(form.contact_number_list[i])
                        "
                        label="電話番号"
                        dense
                        outlined
                        filled
                        :rules="form.inputRules"
                        hide-details
                        class="mt-2"
                        @input="removeHyphen($event, i)"
                      />
                    </v-flex>
                  </v-layout>
                </template>
              </div>

              <v-divider />
              <div class="fw-bold my-3">
                住所を追加
                <v-btn icon x-small color="primary" @click="addAddressForm">
                  <s-icon icon="feather-plus" color="current" size="xl" />
                </v-btn>
                <v-btn
                  v-show="form.address_list.length > 1"
                  color=""
                  class="ml-2"
                  small
                  @click="popAddressForm"
                >
                  削除
                </v-btn>
              </div>
              <div class="">
                <template v-for="(item, j) in form.address_list">
                  <v-layout v-if="j > 0" :key="j" class="mb-2">
                    <v-flex xs3>
                      <v-text-field
                        v-model="form.address_list[j][0]"
                        v-mask="'###-####'"
                        label="郵便番号"
                        dense
                        outlined
                        filled
                        validate-on-blur
                        class=""
                        hide-details
                      />
                    </v-flex>
                    <v-flex xs9>
                      <v-text-field
                        v-model="form.address_list[j][1]"
                        label="住所"
                        dense
                        outlined
                        filled
                        :rules="form.inputRules"
                        class="pl-3"
                        hide-details
                      />
                    </v-flex>
                  </v-layout>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
    </vue-perfect-scrollbar>

    <v-toolbar height="50">
      <v-btn
        small
        :disabled="!form.valid"
        color="primary"
        validate-on-blur
        @click="submit"
      >
        {{ $t('message.submit') }}
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import Axios from 'axios'
import { AsYouType } from 'libphonenumber-js'
import Snackbar from 'Helpers/snackbar/index'

export default {
  props: {
    getUserSession: {
      type: Function,
      required: true,
    },
    journalItems: {
      type: Object,
      required: true,
    },
  },
  data() {
    var t = this
    return {
      user_id: '',
      organization_id: '',

      showError: false,

      menu2: false,
      flag: {
        edit: false,
      },
      form: {
        valid: true,
        full_name: '',
        date_created: '',
        company_name: '',
        excludeCompanyName: [],
        industry_id: '',
        nameRules: [
          (v) => !!v || '必ず入力ください',
          (v) =>
            (v && v.replace(/\s/g, '').length >= 1) || '※この項目は必須です',
          (v) => v.length <= 50 || '50' + t.$t('message.character_max_length'),
        ],
        inputRules: [
          (v) => v.length < 255 || '255' + t.$t('message.character_max_length'),
        ],
        email: '',
        full_name_rules: [(v) => !!v || '必ず入力ください'],
        emailRules: [
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              v
            ) || '正しい形式で入力ください',
        ],
        items: ['業種1', '業種2', '業種3', '業種4'],
        items_industry_list: [],
        contact_number_list: [],
        address_list: [],
        zip: '',
        settlement_day: '',
        settlement_month: '',
        date: null,
      },
      form_2: {
        valid: true,
        new_password: '',
        repeat_new_password: '',
        current_password: '',
        passwordRules: [
          (v) => !!v || '※この項目は必須です',
          (v) =>
            (v && v.length >= 8) ||
            '最低8文字以上のパスワードを設定してください。',
        ],
      },
    }
  },
  computed: {
    phoneNumberWithhyphen: function () {
      return function (number) {
        return new AsYouType('JP').input(number)
      }
    },
  },
  mounted() {
    try {
      this.getUserForm()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    addCompanyForm() {
      try {
        this.form.excludeCompanyName.push('')
      } catch (e) {
        console.log(e)
      }
    },
    addPhoneNumberForm() {
      try {
        this.form.contact_number_list.push('')
      } catch (e) {
        console.log(e)
      }
    },
    popPhoneNumberForm() {
      try {
        this.form.contact_number_list.pop()
      } catch (e) {
        console.log(e)
      }
    },
    addAddressForm() {
      try {
        this.form.address_list.push(['', ''])
      } catch (e) {
        console.log(e)
      }
    },
    popAddressForm() {
      try {
        this.form.address_list.pop()
      } catch (e) {
        console.log(e)
      }
    },
    getUserForm() {
      try {
        Axios.get(this.$store.getters.apiUserSessionFunction, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
          params: {
            user_organization_id: this.$store.getters.getUserOrganizationID,
          },
        }).then((response) => {
          if (response.data.status == 'success') {
            let data = response.data
            this.user_id = data.user.id
            if (data.organization.industry_id) {
              this.form.industry_id = data.organization.industry_id
            }
            this.organization_id = data.organization.id
            this.form.company_name = data.organization.name

            this.form.excludeCompanyName = data.exclude_company_names.map(
              (v) => {
                return v.exclude_company_name
              }
            )

            this.form.full_name = data.user.full_name
            this.form.email = data.user.email
            this.form.date_created = data.user.date_created
            this.form.zip = data.organization.zipcode
              ? data.organization.zipcode
              : ''
            this.form.address_list = data.organization.address_list
              ? data.organization.address_list
              : [['', '']]
            this.form.contact_number_list = data.organization
              .contact_number_list
              ? data.organization.contact_number_list
              : ['']

            this.form.settlement_day = parseInt(
              data.organization.settlement_day
            )
            this.form.settlement_month = parseInt(
              data.organization.settlement_month
            )
            this.form.items_industry_list = data.industry
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    submit() {
      try {
        if (this.$refs.form.validate()) {
          const apiUrl = this.$store.getters.apiUserSessionFunction
          const data = {
            user_id: this.user_id,
            full_name: this.form.full_name,
            company_name: this.form.company_name,
            exclude_company_name: this.form.excludeCompanyName,
            address_list: this.form.address_list,
            contact_number_list: this.form.contact_number_list,
            settlement_day: this.form.settlement_day,
            settlement_month: this.form.settlement_month,
            industry_id: this.form.industry_id,
            organization_id: this.organization_id,
            auth_token: this.$store.getters.getAuthToken,
          }
          this.$store
            .dispatch('editSession', {
              data,
              apiUrl,
            })
            .then((response) => {
              if (response.data.status == 'success') {
                this.$store.commit('changeCompanyname', this.form.company_name)
                this.getUserSession()
                Snackbar.success('更新に成功しました')
              } else {
                Snackbar.error(response.data.error)
              }
            })
        }
      } catch (err) {
        console.log(err)
      }
    },
    resetPassword() {
      try {
        this.showError = false
        if (this.$refs.form_reset.validate()) {
          if (this.form_2.new_password == this.form_2.repeat_new_password) {
            const apiUrl = this.$store.getters.apiPasswordInstanceFunction
            const data = {
              current_password: this.form_2.current_password,
              new_password: this.form_2.new_password,
              confirm_new_password: this.form_2.repeat_new_password,
              auth_token: this.$store.getters.getAuthToken,
            }
            this.$store
              .dispatch('sessionResetPassword', {
                data,
                apiUrl,
              })
              .then((response) => {
                if (response.data.error) {
                  this.$refs.form_reset.reset()
                }
                if (response.data.status == 'success') {
                  Snackbar.success(
                    this.$t('message.user_setting_success_password')
                  )
                  this.$refs.form_reset.reset()
                  this.showError = false
                } else {
                  Snackbar.error(response.data.error)
                }
              })
          } else {
            this.showError = true
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    removeHyphen(phoneNumber, index) {
      const phoneNumbeWithoutHypen = phoneNumber.replace(/-/g, '')
      this.$set(this.form.contact_number_list, index, phoneNumbeWithoutHypen)
    },
  },
}
</script>
