<template>
  <base-dialog
    ref="dialog"
    size="large"
    :title="computedText.title"
    :submit-text="$t('message.save')"
    :cancel-text="$t('message.modal_cancel_btn')"
    @submit="submit"
  >
    <template #card-text>
      <v-checkbox
        v-show="showCheckbox"
        v-model="userBank.default"
        label="デフォルト口座に設定する"
        dense
      />
      <form-user-bank ref="formUserBank" :user-bank="userBank" />
    </template>
  </base-dialog>
</template>

<script>
import FormUserBank from '@/views/settings/components/form/FormUserBank'
import { getUserBankForm } from '@/objects/account/userBank'

const userBank = getUserBankForm()

export default {
  components: {
    FormUserBank,
  },
  props: {},
  data() {
    return {
      userBank,
      editMode: false,
      showCheckbox: false,
    }
  },
  computed: {
    computedText() {
      const title = this.editMode
        ? this.$t('message.edit')
        : this.$t('message.add')
      const obj = {
        title: title,
      }
      return obj
    },
  },
  methods: {
    open(mode, item, showCheckbox) {
      this.showCheckbox = showCheckbox
      this.setEditItem(item)
      this.editMode = mode == 'edit'
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    setEditItem(item) {
      this.userBank.bank_name = item.bank_name
      this.userBank.bank_code = item.bank_code
      this.userBank.bank_account_name = item.bank_account_name
      this.userBank.bank_account_type = item.bank_account_type
      this.userBank.bank_account_number = item.bank_account_number
      this.userBank.user_code = item.user_code
      this.userBank.default = this.showCheckbox ? item.default : true
      this.$nextTick(() => {
        this.userBank.bank_branch_name = item.bank_branch_name
        this.userBank.bank_branch_code = item.bank_branch_code
      })
    },
    reset() {
      this.userBank = getUserBankForm()
    },
    submit() {
      if (this.validate()) {
        if (this.editMode) {
          this.$emit('submit:update', this.userBank)
        } else {
          this.$emit('submit:create', this.userBank)
        }
      }
    },
    validate() {
      try {
        return this.$refs.formUserBank.validate()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
