<template>
  <v-card outlined tile class="w-100">
    <vue-perfect-scrollbar>
      <v-card-text class="h-without-header-footer">
        <v-form
          ref="journalSetting"
          class="pa-4 mb-5"
          style="max-width: 650px"
          lazy-validation
        >
          <v-card class="sweeep-dialog">
            <v-card-title>
              管理項目（会社設定）
              <s-icon
                tooltip="仕訳で使用する管理項目を設定します。"
                class="tw-ml-2"
                icon="feather-info"
              />
            </v-card-title>
            <v-card-text class="px-4 py-5">
              <v-row class="px-4">
                <v-col cols="6"> 仕訳項目設定 </v-col>
                <v-col cols="3"> 借方 </v-col>
                <v-col cols="3"> 貸方 </v-col>
              </v-row>
              <form-journal-item
                :form="form"
                name="account_title"
                label="勘定科目の表示名"
              />

              <form-journal-item
                :form="form"
                name="sub_account"
                label="補助科目の表示名"
                :disable="form.setting.account_title === 0"
              />

              <form-journal-item
                :form="form"
                name="department"
                label="部門の表示名"
                description="チェックを外した場合でもマスタ編集および権限設定は可能です。"
              />

              <form-journal-item
                :form="form"
                name="vendor"
                label="取引先の表示名"
              />

              <form-journal-item
                :form="form"
                name="project"
                label="プロジェクトの表示名"
              />

              <form-journal-item
                :form="form"
                name="segment"
                label="セグメントの表示名"
              />

              <form-journal-item
                :form="form"
                name="item"
                label="品目の表示名"
              />

              <form-journal-item :form="form" name="tag" label="タグの表示名" />

              <form-journal-item
                :form="form"
                name="walletable"
                label="口座の表示名"
              />

              <form-journal-item
                :form="form"
                name="tax_class"
                label="税区分の表示名"
              />

              <v-row class="px-4">
                <v-col cols="6"> テキスト項目設定 </v-col>
                <v-col cols="6" />
              </v-row>

              <form-journal-text-setting
                :form="form"
                name="description"
                label="摘要の表示名"
              />

              <form-journal-text-setting
                :form="form"
                name="free_text_1"
                label="備考1の表示名"
              />

              <form-journal-text-setting
                :form="form"
                name="free_text_2"
                label="備考2の表示名"
              />
            </v-card-text>
          </v-card>

          <v-card class="sweeep-dialog mt-4">
            <v-card-title>
              支払タブのメモ名称設定
              <s-icon
                tooltip="支払いタブのメモの名称の変更が可能です。"
                class="tw-ml-2"
                icon="feather-info"
              />
            </v-card-title>

            <v-card-text class="px-8 pb-10">
              <v-row
                v-for="i in 3"
                :key="`payment_note${i}`"
                no-gutters
                class="ma-2"
              >
                <s-text-field
                  v-model="form.definition[`payment_note${i}`]"
                  :label="`支払メモ${i}`"
                />
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="sweeep-dialog mt-4">
            <v-card-title>
              最大文字数の制限
              <s-icon
                tooltip="仕訳編集時に入力できる文字数を制限できます。"
                class="tw-ml-2"
                icon="feather-info"
              />
            </v-card-title>

            <v-card-text class="px-8 pb-10">
              <v-row no-gutters>
                <v-radio-group v-model="form.setting.word_or_byte" row>
                  <v-radio color="primary" label="文字数" :value="1" />
                  <v-radio color="primary" label="バイト数" :value="2" />
                </v-radio-group>
              </v-row>

              <form-maxlen
                v-show="form.setting.description"
                :form="form"
                :definition="definition"
                :suffix="suffix"
                name="description"
              />
              <form-maxlen
                v-show="form.setting.free_text_1"
                :form="form"
                :definition="definition"
                :suffix="suffix"
                name="free_text_1"
              />
              <form-maxlen
                v-show="form.setting.free_text_2"
                :form="form"
                :definition="definition"
                :suffix="suffix"
                name="free_text_2"
              />
            </v-card-text>
          </v-card>

          <v-card class="sweeep-dialog mt-4">
            <v-card-title>
              承認の段階数
              <s-icon
                tooltip="承認の最大回数（階層数）を設定できます。"
                class="tw-ml-2"
                icon="feather-info"
              />
            </v-card-title>

            <v-card-text class="pa-8">
              <v-layout flex row wrap mt2>
                <v-flex xs3 class="py-0 pl-3">
                  <span class="fs-12 pt-3"> 仕訳承認回数 </span>
                </v-flex>

                <v-flex xs3 class="my-0">
                  <v-autocomplete
                    v-model="form.approve_level"
                    class="fs-10 py-0"
                    suffix="回"
                    :items="approveLevelItems"
                  />
                </v-flex>
              </v-layout>
              <v-layout flex row wrap mt2>
                <v-flex xs3 class="py-0 pl-3">
                  <span class="fs-12 pt-3"> 支払確認回数 </span>
                </v-flex>

                <v-flex xs3 class="my-0">
                  <v-autocomplete
                    v-model="form.pay_confirm_level"
                    class="fs-10 py-0"
                    suffix="回"
                    :items="payConfirmLevelItems"
                  />
                </v-flex>
              </v-layout>
              <v-checkbox
                v-model="form.send_approval_request"
                label="承認依頼メールを送信する"
                hide-details
              />
              <v-checkbox
                v-model="form.settingJournalLockMode"
                label="承認厳格ロック"
                hide-details
              />
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-toolbar height="50">
      <v-btn small color="primary" @click="submit()"> 更新 </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import FormJournalItem from './form/FormJournalItem'
import FormJournalTextSetting from './form/FormJournalTextSetting'
import FormMaxlen from './form/FormMaxlen'

export default {
  components: {
    FormJournalItem,
    FormJournalTextSetting,
    FormMaxlen,
  },
  props: {
    definition: { type: Object, required: true },
    getUserSession: { type: Function, required: true },
  },
  data() {
    var t = this
    return {
      suffix: '文字',
      form: {
        setting: {
          account_title: false,
          sub_account: false,
          department: false,
          tax_class: false,
          vendor: false,
          project: false,
          segment: false,
          item: false,
          tag: false,
          walletable: false,
          word_or_byte: 1,
          description: false,
          free_text_1: false,
          free_text_2: false
        },
        journalRules: {
          account_title: { input_debit: -1, input_credit: -1 },
          sub_account: { input_debit: -1, input_credit: -1 },
          department: { input_debit: -1, input_credit: -1 },
          tax_class: { input_debit: -1, input_credit: -1 },
          vendor: { input_debit: -1, input_credit: -1 },
          project: { input_debit: -1, input_credit: -1 },
          segment: { input_debit: -1, input_credit: -1 },
          item: { input_debit: -1, input_credit: -1 },
          tag: { input_debit: -1, input_credit: -1 },
          walletable: { input_debit: -1, input_credit: -1 },
        },
        maxlen: {
          description: 255,
          free_text_1: 255,
          free_text_2: 255,
        },
        definition: {
          account_title: '',
          sub_account: '',
          department: '',
          tax_class: '',
          vendor: '',
          project: '',
          segment: '',
          item: '',
          tag: '',
          walletable: '',
          free_text_1: '',
          free_text_2: '',
          description: '',
        },
        approve_level: null,
        pay_confirm_level: 3,
        send_approval_request: true,
        settingJournalLockMode: 0
      },
      approveLevelItems: [1, 2, 3, 4, 5],
      payConfirmLevelItems: [0, 1, 2, 3],
      inputRulesNumber: [
        (v) => (!!v && v > 0) || t.$t('message.form_input_required_above_zero'),
        (v) =>
          (!!v && v < 256) || t.$t('message.form_input_required_under_256'),
      ],
    }
  },
  watch: {
    'form.setting.word_or_byte': function (v) {
      if (v == 1) {
        this.suffix = '文字'
      } else {
        this.suffix = 'バイト'
      }
    },
  },
  mounted() {
    try {
      this.getOrganizationItem()
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    checkUsageSetting(v) {
      return v ? 1 : 0
    },
    getOrganizationItem() {
      Axios.get(this.$store.getters.apiSettingCompanyItem, {
        headers: {
          Authorization: this.$store.getters.getAuthToken,
        },
        params: {
          user_organization_id: this.$store.getters.getUserOrganizationID,
        },
      }).then((response) => {
        if (response.data.status == 'success') {
          this.form.setting.account_title = this.checkUsageSetting(
            response.data.data.settings_account_title
          )
          this.form.setting.sub_account = this.checkUsageSetting(
            response.data.data.settings_subaccount
          )
          this.form.setting.department = this.checkUsageSetting(
            response.data.data.settings_department
          )
          this.form.setting.tax_class = this.checkUsageSetting(
            response.data.data.settings_tax
          )
          this.form.setting.project = this.checkUsageSetting(
            response.data.data.settings_project
          )
          this.form.setting.segment = this.checkUsageSetting(
            response.data.data.settings_segment
          )
          this.form.setting.subaccount = this.checkUsageSetting(
            response.data.data.settings_subaccount
          )
          this.form.setting.vendor = this.checkUsageSetting(
            response.data.data.settings_vendor
          )
          this.form.setting.item = this.checkUsageSetting(
            response.data.data.settings_item
          )
          this.form.setting.tag = this.checkUsageSetting(
            response.data.data.settings_tag
          )
          this.form.setting.walletable = this.checkUsageSetting(
            response.data.data.settings_walletable
          )
          this.form.setting.description =
            response.data.data.settings_description
          this.form.setting.free_text_1 =
            response.data.data.settings_free_text_1
          this.form.setting.free_text_2 =
            response.data.data.settings_free_text_2
          this.form.definition = response.data.data.definition
          this.form.setting.word_or_byte = response.data.data.word_or_byte

          this.form.settingJournalLockMode = response.data.data.setting_journal_lock_mode

          this.setJournalRules(response.data.data.journal_rules)
          if (response.data.data.word_or_byte == 1) {
            this.suffix = '文字'
          } else {
            this.suffix = 'バイト'
          }
          this.form.maxlen.description =
            response.data.data.setting_maxlen_description
          this.form.maxlen.free_text_1 =
            response.data.data.setting_maxlen_free_text_1
          this.form.maxlen.free_text_2 =
            response.data.data.setting_maxlen_free_text_2
          this.form.approve_level = response.data.data.approve_level
          this.form.pay_confirm_level = response.data.data.pay_confirm_level
          this.form.send_approval_request =
            response.data.data.send_approval_request
        } else {
          Snackbar.error(response.data.error)
        }
      })
    },
    setJournalRules(values) {
      const usageSettings = this.form.setting
      const journalRules = this.form.journalRules
      Object.keys(journalRules).forEach((key) => {
        // 使用する設定の場合のみ値をセット、使用しない場合はデフォルトの-1が適用される
        if (usageSettings[key]) {
          journalRules[key] = values[key]
        }
      })
    },
    getJournalRulesForRequest() {
      const checkVal = (v) => {
        return v === -1 ? 0 : v
      }
      const rules = {}
      const rulesForm = this.form.journalRules
      Object.keys(this.form.journalRules).forEach((name) => {
        // -1 => 0 に変換(サーバーは0~2のみを受け付ける)
        rules[name] = {
          input_credit: checkVal(rulesForm[name].input_credit),
          input_debit: checkVal(rulesForm[name].input_debit),
        }
      })
      return rules
    },
    submit() {
      try {
        let data = {
          approve_level: this.form.approve_level,
          pay_confirm_level: this.form.pay_confirm_level,
          send_approval_request: this.form.send_approval_request,
          setting: this.form.setting,
          maxlen: this.form.maxlen,
          definition: this.form.definition,
          journal_rules: this.getJournalRulesForRequest(),
          setting_journal_lock_mode: this.form.settingJournalLockMode ? 1: 0
        }
        data.organization_id = this.$store.getters.getOrganizationID
        Axios.post(this.$store.getters.apiSettingCompanyItem, data, {
          headers: { Authorization: this.$store.getters.getAuthToken },
        }).then((response) => {
          if (response.data.status) {
            Snackbar.success('更新に成功しました')
            const displaySetting = {
              account_title: data.setting.account_title,
              sub_account: data.setting.sub_account,
              department: data.setting.department,
              tax: data.setting.tax_class,
              segment: data.setting.segment,
              project: data.setting.project,
              vendor: data.setting.vendor,
              item: data.setting.item,
              tag: data.setting.tag,
              walletable: data.setting.walletable,
              description: data.setting.description,
              free_text_1: data.setting.free_text_1,
              free_text_2: data.setting.free_text_2,
              maxlen_description: data.maxlen.description,
              maxlen_free_text_1: data.maxlen.free_text_1,
              maxlen_free_text_2: data.maxlen.free_text_2,
              wordOrByte: data.setting.word_or_byte,
            }
            const approveLevel = data.approve_level
            const payConfirmLevel = data.pay_confirm_level
            const sendApprovalRequest = data.send_approval_request
            const definition = { ...data.definition }
            const settingJournalLockMode = data.setting_journal_lock_mode
            definition.tax = definition.tax_class
            this.$store.commit('setOrganizationSetting', {
              displaySetting,
              approveLevel,
              payConfirmLevel,
              sendApprovalRequest,
              definition,
              settingJournalLockMode,
            })
            this.getUserSession()
          } else {
            Snackbar.error('更新に失敗しました')
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
