<template>
  <v-card outlined tile class="w-100">
    <vue-perfect-scrollbar>
      <v-card-text class="h-without-header-footer">
        <v-form
          ref="journalSetting"
          class="pa-4 mb-5"
          lazy-validation
          style="max-width: 600px"
        >
          <v-card class="sweeep-dialog">
            <v-card-title>
              請求書データ編集設定
              <about-invoice-edit-setting />
            </v-card-title>
            <v-card-text class="pt-1 pb-5">
              <v-row class="ml-1">
                <v-checkbox
                  v-model="form.function.journal"
                  label="仕訳機能を利用"
                  hide-details
                />
                <v-checkbox
                  v-model="form.function.foreign_currency"
                  label="外貨入力機能を利用"
                  class="ml-4"
                  hide-details
                />
              </v-row>
              <v-row class="ml-1">
                <v-checkbox
                  v-model="form.function.pay_journal"
                  label="支払仕訳機能を利用"
                  hide-details
                  :disabled="!form.function.journal || !form.function.payment"
                />
              </v-row>
              <v-row class="ml-1">
                <v-checkbox
                  v-model="form.function.payment"
                  label="支払機能を利用"
                  hide-details
                  readonly
                />
                <v-checkbox
                  v-if="form.function.payment"
                  v-model="form.function.paid_by_customer"
                  class="ml-4"
                  label="振込手数料の先方負担指定を利用"
                  hide-details
                />
                <v-checkbox
                  v-model="form.function.avoid_bank_holidays"
                  label="支払日が銀行の休日の場合に手前の日付に自動変更する"
                  hide-details
                />
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="sweeep-dialog mt-4">
            <v-card-title>
              請求書アップロード時のデフォルト仕訳
              <s-icon
                tooltip="請求書データを読み取った際のデフォルト仕訳を指定できます。<br>（ただし、学習済の場合は学習済仕訳が優先されます。）"
                class="tw-ml-2"
                icon="feather-info"
              />
            </v-card-title>
            <v-card-text class="pa-6">
              <journal-form-header no-padding />
              <v-row no-gutters>
                <template v-for="dc in ['debit', 'credit']">
                  <v-col :key="`default_${dc}_journal`" cols="6">
                    <v-card
                      tile
                      outlined
                      class="pb-3"
                      style="background-color: #fcfcfc"
                    >
                      <div class="px-2 pt-2">
                        <journal-iterators
                          :journal="defaultInvoice"
                          :editable="true"
                          :entry-side="dc"
                          @change="
                            checkChangeSubAccount($event, defaultInvoice)
                            onChangeJournal($event, defaultInvoice)
                          "
                        />
                      </div>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
              <v-card
                v-if="displaySetting.description"
                tile
                outlined
                class="pa-3"
                style="background-color: #fcfcfc"
              >
                <journal-text-field
                  v-model="defaultInvoice.description"
                  description-field
                  :chip="textFormChip"
                  :editable="true"
                  :mode="1"
                  :tooltip="definition.description"
                  :counter="displaySetting.maxlen_description"
                  :check-byte="displaySetting.wordOrByte == 2"
                />
              </v-card>
            </v-card-text>
          </v-card>

          <v-card class="sweeep-dialog mt-4">
            <v-card-title>
              請求書アップロード時のデフォルト支払サイト
              <s-icon
                tooltip="請求書データを読み取った際の初期支払サイトを設定できます。<br>伝票日付から支払サイトを基準に支払日を自動計算します。"
                class="tw-ml-2"
                icon="feather-info"
              />
            </v-card-title>
            <v-card-text class="pa-4">
              <v-layout>
                <v-flex xs4 class="px-3">
                  <template>
                    <v-autocomplete
                      v-model="form.pay_month"
                      :items="pay_month_list"
                      label="支払月"
                      item-text="name"
                      item-value="value"
                      hide-details
                      clearable
                      class="small-input"
                      dense
                      outlined
                      filled
                    />
                  </template>
                </v-flex>
                <v-flex xs3 class="px-1">
                  <template>
                    <v-autocomplete
                      v-model="form.pay_day"
                      :items="pay_day_list"
                      label="日"
                      item-text="name"
                      item-value="value"
                      hide-details
                      clearable
                      class="small-input"
                      dense
                      outlined
                      filled
                    />
                  </template>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <v-card v-if="form.function.pay_journal" class="sweeep-dialog mt-4">
            <v-card-title>
              支払仕訳機能
              <s-icon
                tooltip="費用計上後の支払時仕訳（預金／未払金など）を作成しダウンロードすることができます。"
                class="tw-ml-2"
                icon="feather-info"
              />
            </v-card-title>

            <div class="form_pay_journal">
              <v-container fluid>
                <div class="mb-3">
                  デフォルト預金仕訳（貸方）
                  <s-icon
                    tooltip="支払仕訳作成時にデフォルトで入力される預金仕訳の貸方を設定します。"
                    class="tw-ml-2"
                    icon="feather-info"
                  />
                </div>
                <v-row no-gutters>
                  <v-col cols="12">
                    <journal-form-header
                      no-padding
                      debit-cols="4"
                      credit-cols="8"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-card
                      tile
                      outlined
                      class="pb-3"
                      style="background-color: #fcfcfc"
                    >
                      <div class="pl-2 pr-4 pt-2">
                        <journal-iterators
                          :journal="emptyJounal"
                          :editable="true"
                          :entry-side="'debit'"
                          :get-form-disable="
                            () => {
                              return true
                            }
                          "
                        />
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card
                      tile
                      outlined
                      class="pb-3"
                      style="background-color: #fcfcfc; border-right: none"
                    >
                      <div class="px-2 pt-2 mr-2">
                        <journal-iterators
                          :journal="defaultPayment"
                          :editable="true"
                          :entry-side="'credit'"
                          :get-form-disable="getPayFormDisable"
                          @change="
                            checkChangeSubAccount($event, defaultPayment)
                            onChangeJournal($event, defaultPayment)
                          "
                        />
                      </div>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="4"
                    :style="{ paddingTop: settingsPaddingTop + 'px' }"
                    class="default-jouranl-settings-area"
                  >
                    <template v-for="key in settingsKey">
                      <v-checkbox
                        v-if="displaySetting[key]"
                        :key="`default-payment-${key}`"
                        :value="
                          defaultPaymentSetting[`credit_${key}`]
                            .copy_invoice_credit
                        "
                        color="primary"
                        class="default-jouranl-checkbox"
                        hide-details
                        :label="$t('message.my_company_copy_invoice')"
                        @change="
                          onChangeJournalSettings('payment', key, $event)
                        "
                      />
                    </template>
                  </v-col>
                  <v-col v-if="displaySetting.description" cols="12">
                    <v-card
                      tile
                      outlined
                      class="pa-3"
                      style="background-color: #fcfcfc"
                    >
                      <journal-text-field
                        v-model="defaultPayment.description"
                        description-field
                        :chip="textFormChip"
                        :editable="true"
                        :mode="1"
                        :tooltip="definition.description"
                        :counter="displaySetting.maxlen_description"
                        :check-byte="displaySetting.wordOrByte == 2"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />

              <v-container fluid>
                <div class="mb-3">
                  デフォルト預り源泉税仕訳（貸方）
                  <s-icon
                    tooltip="支払仕訳作成時にデフォルトで入力される預り源泉税仕訳の貸方を設定します。"
                    class="tw-ml-2"
                    icon="feather-info"
                  />
                </div>
                <v-row no-gutters>
                  <v-col cols="12">
                    <journal-form-header
                      no-padding
                      debit-cols="4"
                      credit-cols="8"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-card
                      tile
                      outlined
                      class="pb-3"
                      style="background-color: #fcfcfc"
                    >
                      <div class="pl-2 pr-4 pt-2">
                        <journal-iterators
                          :journal="emptyJounal"
                          :editable="true"
                          :entry-side="'debit'"
                          :get-form-disable="
                            () => {
                              return true
                            }
                          "
                        />
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card
                      tile
                      outlined
                      class="pb-3"
                      style="background-color: #fcfcfc; border-right: none"
                    >
                      <div class="px-2 pt-2">
                        <journal-iterators
                          :journal="defaultGensen"
                          :editable="true"
                          :entry-side="'credit'"
                          :get-form-disable="getGensenFormDisable"
                          @change="
                            checkChangeSubAccount($event, defaultGensen)
                            onChangeJournal($event, defaultGensen)
                          "
                        />
                      </div>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="4"
                    class="default-jouranl-settings-area"
                    :style="{ paddingTop: settingsPaddingTop + 'px' }"
                  >
                    <template v-for="key in settingsKey">
                      <v-checkbox
                        v-if="displaySetting[key]"
                        :key="`default-gensen-${key}`"
                        :value="
                          defaultGensenSetting[`credit_${key}`]
                            .copy_invoice_credit
                        "
                        color="primary"
                        class="default-jouranl-checkbox"
                        hide-details
                        :label="$t('message.my_company_copy_invoice')"
                        @change="onChangeJournalSettings('gensen', key, $event)"
                      />
                    </template>
                  </v-col>
                  <v-col v-if="displaySetting.description" cols="12">
                    <v-card
                      tile
                      outlined
                      class="pa-3"
                      style="background-color: #fcfcfc"
                    >
                      <journal-text-field
                        v-model="defaultGensen.description"
                        description-field
                        :chip="textFormChip"
                        :editable="true"
                        :mode="1"
                        :tooltip="definition.description"
                        :counter="displaySetting.maxlen_description"
                        :check-byte="displaySetting.wordOrByte == 2"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card>
        </v-form>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-toolbar height="50">
      <v-btn small color="primary" @click="submit()"> 更新 </v-btn>
    </v-toolbar>
  </v-card>
</template>
<style scoped>
.default-jouranl-checkbox {
  height: 28px;
  margin-top: 8px;
}
.default-jouranl-settings-area {
  background-color: #fcfcfc;
  border: thin #ccc solid;
  border-left: white;
}
</style>

<script>
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import { setDisplaySetting } from 'Helpers/journal/index'
import { mapGetters } from 'vuex'
import { sliceText, onChangeJournal } from 'Helpers/journal'
import JournalIterators from 'Components/Journal/JournalIterators.vue'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import JournalTextField from 'Components/Journal/JournalTextField.vue'
import AboutInvoiceEditSetting from './menu/AboutInvoiceEditSetting.vue'

export default {
  components: {
    JournalIterators,
    JournalFormHeader,
    JournalTextField,
    AboutInvoiceEditSetting,
  },
  props: {
    journalItems: {
      type: Object,
      required: true,
    },
    getUserSession: {
      type: Function,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resportSetting: false,
      defaultInvoice: {
        debit_account_title: {},
        debit_subject: {},
        debit_sub_account: {},
        debit_department: {},
        debit_tax: {},
        debit_project: {},
        debit_segment: {},
        debit_vendor: {},
        debit_item: {},
        debit_tag: {},
        debit_walletable: {},
        credit_account_title: {},
        credit_subject: {},
        credit_sub_account: {},
        credit_department: {},
        credit_tax: {},
        credit_project: {},
        credit_segment: {},
        credit_vendor: {},
        credit_item: {},
        credit_tag: {},
        credit_walletable: {},
        description: '',
      },
      defaultPayment: {
        credit_account_title: {},
        credit_subject: {},
        credit_sub_account: {},
        credit_department: {},
        credit_tax: {},
        credit_project: {},
        credit_segment: {},
        credit_vendor: {},
        credit_item: {},
        credit_tag: {},
        credit_walletable: {},
        description: '',
      },
      defaultPaymentSetting: {
        credit_department: {
          copy_invoice_credit: false,
        },
        credit_project: {
          copy_invoice_credit: false,
        },
        credit_segment: {
          copy_invoice_credit: false,
        },
        credit_vendor: {
          copy_invoice_credit: false,
        },
        credit_item: {
          copy_invoice_credit: false,
        },
        credit_tag: {
          copy_invoice_credit: false,
        },
        credit_walletable: {
          copy_invoice_credit: false,
        },
      },
      defaultGensen: {
        credit_account_title: {},
        credit_subject: {},
        credit_sub_account: {},
        credit_department: {},
        credit_tax: {},
        credit_project: {},
        credit_segment: {},
        credit_vendor: {},
        credit_item: {},
        credit_tag: {},
        credit_walletable: {},
        description: '',
      },
      emptyJounal: {},
      defaultGensenSetting: {
        credit_department: {
          copy_invoice_credit: false,
        },
        credit_project: {
          copy_invoice_credit: false,
        },
        credit_segment: {
          copy_invoice_credit: false,
        },
        credit_vendor: {
          copy_invoice_credit: false,
        },
        credit_item: {
          copy_invoice_credit: false,
        },
        credit_tag: {
          copy_invoice_credit: false,
        },
        credit_walletable: {
          copy_invoice_credit: false,
        },
      },
      pay_month_list: [],
      pay_day_list: [],
      form: {
        pay_month: null,
        pay_day: null,
        showTabPattern: '2',
        function: {
          journal: true,
          pay_journal: false,
          payment: true,
          paid_by_customer: false,
          avoid_bank_holidays: false,
          foreign_currency: false,
        },
      },
      descriptionRules: [
        (v) =>
          v.length <= this.displaySetting.maxLenDescription ||
          `${this.displaySetting.maxLenDescription}字以内で入力してください`,
      ],
      settingsKey: [
        'department',
        'vendor',
        'project',
        'segment',
        'walletable',
        'item',
        'tag',
      ],
    }
  },
  computed: {
    ...mapGetters(['displaySetting', 'definition']),
    textFormChip() {
      return sliceText(this.definition.description, 0, 4)
    },
    settingsPaddingTop() {
      const heightPerItem = 38
      const keys = ['account_title', 'subject']
      const displaySetting = this.displaySetting
      const count = keys.filter((key) => !displaySetting[key]).length
      return heightPerItem * (keys.length - count)
    },
  },
  watch: {
    'form.function.journal': {
      handler(value) {
        if (!value) {
          this.form.function.pay_journal = false
        }
      },
      immediate: true,
    },
  },
  created() {
    this.emptyJounal = { ...this.defaultInvoice }
    this.getDefaultJournal()
    this.getDisplaySetting()
    this.createPaySiteList()
    this.getResportSetting()
  },
  methods: {
    getSubjects(account_title) {
      try {
        let subjects = this.journalItems.account_title.find(
          (item) => item.id == account_title.id
        ).subjects
        return subjects
      } catch (e) {
        return []
      }
    },
    getResportSetting() {
      try {
        Axios.get(this.$store.getters.apiResportSettingUrl, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { org_id: this.$store.getters.getOrganizationID },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.resportSetting = response.data.resport_setting
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    submit() {
      if (this.$refs.journalSetting.validate()) {
        this.updateDefaultJournalSetting()
      } else {
        Snackbar.error('再度入力事項をご確認ください。')
      }
    },
    getDefaultJournal() {
      try {
        Axios.get(this.$store.getters.apiDefaultJournalUrl, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { organization_id: this.$store.getters.getOrganizationID },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.defaultInvoice = response.data.invoice_journal
            this.defaultPayment = response.data.payment_journal
            this.defaultGensen = response.data.gensen_journal
            this.defaultPaymentSetting = response.data.payment_journal_setting
            this.defaultGensenSetting = response.data.gensen_journal_setting
            //property name
            this.$set(
              this.defaultInvoice,
              'debit_sub_account',
              this.defaultInvoice.debit_subject
            )
            this.$set(
              this.defaultInvoice,
              'credit_sub_account',
              this.defaultInvoice.credit_subject
            )
            this.$set(
              this.defaultPayment,
              'credit_sub_account',
              this.defaultPayment.credit_subject
            )
            this.$set(
              this.defaultGensen,
              'credit_sub_account',
              this.defaultGensen.credit_subject
            )
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    updateDefaultJournalSetting() {
      try {
        const data = {
          organization_id: this.$store.getters.getOrganizationID,
          invoice_journal: this.defaultInvoice,
          payment_journal: this.defaultPayment,
          payment_journal_setting: this.defaultPaymentSetting,
          gensen_journal: this.defaultGensen,
          gensen_journal_setting: this.defaultGensenSetting,
          setting_pay_day: this.form.pay_day,
          setting_pay_month: this.form.pay_month,
          setting_journal: this.form.function.journal,
          setting_paid_by_customer: this.form.function.paid_by_customer,
          setting_avoid_bank_holidays: this.form.function.avoid_bank_holidays,
          setting_foreign_currency: this.form.function.foreign_currency,
          setting_pay_journal: this.form.function.pay_journal,
          auth_token: this.$store.getters.getAuthToken,
        }
        const apiUrl = this.$store.getters.apiDefaultJournalUrl
        this.$store
          .dispatch('UpdateUserDefaultJournal', {
            data,
            apiUrl,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              Snackbar.success(this.$t('message.success'))
              this.getDefaultJournal()
              const organizationSettings = {
                settingJournal: this.form.function.journal,
                settingPaidByCustomer: this.form.function.paid_by_customer,
                settingAvoidBankHolidays:
                  this.form.function.avoid_bank_holidays,
                settingPayJournal: this.form.function.pay_journal,
                settingForeignCurrency: this.form.foreign_currency,
              }
              this.$store.commit('setOrganizationSetting', organizationSettings)
              this.getUserSession()
            } else {
              Snackbar.error(response.data.error)
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    getDisplaySetting() {
      try {
        setDisplaySetting(this.displaySetting, this.organization)
        this.form.function.journal = this.organization.settingJournal
        this.form.function.paid_by_customer =
          this.organization.settingPaidByCustomer
        this.form.function.avoid_bank_holidays =
          this.organization.settingAvoidBankHolidays
        this.form.function.foreign_currency =
          this.organization.settingForeignCurrency
        this.form.function.pay_journal = this.organization.settingPayJournal
        this.form.pay_month = this.organization.settingPayMonth
        this.form.pay_day = this.organization.settingPayDay
      } catch (e) {
        console.log(e)
      }
    },
    createPaySiteList() {
      try {
        for (let i = 0; i <= 12; i++) {
          if (i == 0) {
            this.pay_month_list.push({ name: '当月', value: i })
          } else {
            this.pay_month_list.push({ name: i + 'ヶ月後', value: i })
          }
        }
        for (let i = 31; i >= 1; i--) {
          if (i == 31) {
            this.pay_day_list.push({ name: '月末', value: i })
          } else {
            this.pay_day_list.push({ name: i + '日', value: i })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    checkChangeSubAccount(event, journal) {
      try {
        if (event.field === 'sub_account') {
          const propertyName = `${event.entrySide}_subject`
          journal[propertyName] = event.obj
        }
      } catch (error) {
        console.log(error)
      }
    },
    getPayFormDisable(journal, entrySide, field) {
      if (['account_title', 'sub_account', 'tax'].indexOf(field) === -1) {
        return this.defaultPaymentSetting[`${entrySide}_${field}`]
          .copy_invoice_credit
      } else {
        return false
      }
    },
    getGensenFormDisable(journal, entrySide, field) {
      if (['account_title', 'sub_account', 'tax'].indexOf(field) === -1) {
        return this.defaultGensenSetting[`${entrySide}_${field}`]
          .copy_invoice_credit
      } else {
        return false
      }
    },
    onChangeJournal(event, journal) {
      try {
        onChangeJournal(event, journal)
      } catch (error) {
        console.log(error)
      }
    },
    onChangeJournalSettings(settingType, key, value) {
      const field = `credit_${key}`
      if (settingType === 'payment') {
        this.defaultPayment[field] = null
        this.defaultPaymentSetting[field].copy_invoice_credit = !!value
      } else {
        this.defaultGensen[field] = null
        this.defaultGensenSetting[field].copy_invoice_credit = !!value
      }
    },
  },
}
</script>
