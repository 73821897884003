<template>
  <v-container v-if="checkPrivilege('settings:read')" fluid pa-0>
    <v-row no-gutters>
      <v-col cols="12" sm="3" lg="2">
        <v-card flat class="h-without-header">
          <settings-sidebar
            :select-setting="selectSetting"
            :selected-item="settingItem"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="9" lg="10" class="border-left-1">
        <v-row no-gutters>
          <template>
            <my-company
              v-if="settingItem == 'myCompany'"
              :get-user-session="getUserSession"
              :journal-items="journalItems"
            />
            <item-for-journal
              v-if="settingItem == 'item'"
              :get-user-session="getUserSession"
              :definition="definition"
            />
            <default-journal
              v-if="settingItem == 'defaultJournal'"
              :journal-items="journalItems"
              :bank-data="bankData"
              :get-user-session="getUserSession"
              :organization="organization"
            />
            <user-bank
              v-if="settingItem == 'userBank'"
              :journal-items="journalItems"
              :bank-data="bankData"
              :get-user-session="getUserSession"
              :organization="organization"
            />
            <ip-restriction v-if="settingItem == 'ip'" />
            <plan v-if="settingItem == 'plan'" />
            <export-settings v-if="settingItem == 'exportJournal'" />
            <email-capture
              v-if="settingItem == 'mailUpload'"
              :definition="definition"
            />
            <user v-if="settingItem == 'user'" />
            <router-view
              :user="user"
              :definition="definition"
              :get-journal-items="getJournalItems"
            />
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCurrentAppLayout } from 'Helpers/helpers'
import SettingsSidebar from './SettingsSidebar'
//基本設定
import MyCompany from './MyCompany'
import ItemForJournal from './components/ItemForJournal'
import DefaultJournal from './components/DefaultJournal'
import UserBank from './components/UserBank'
import IpRestriction from './IpRestriction'
import Plan from './components/Plan'

//他システム提携
import ExportSettings from './ExportSettings'
import EmailCapture from './EmailCapture'
import Application from './Application'

//ユーザ/権限
import User from './User'

export default {
  components: {
    SettingsSidebar,
    MyCompany,
    ItemForJournal,
    DefaultJournal,
    UserBank,
    IpRestriction,
    Plan,
    ExportSettings,
    EmailCapture,
    User,
    Application,
  },
  props: {
    user: Object,
    getUserSession: Function,
    getJournalItems: Function,
    journalItems: Object,
    bankData: Object,
    definition: Object,
    organization: Object,
  },
  data() {
    return {
      settingItem: '',
    }
  },
  methods: {
    selectSetting(link) {
      switch (link) {
        case 'freee':
          this.$router.push('/freee')
          break
        case 'apiUpload':
          this.$router.push('/application')
          break
        case 'sns':
          this.$router.push('/apiupload')
          break
        case 'role':
          this.$router.push('/role')
          break
        default:
          this.$router.push('/settings')
      }
      this.settingItem = link
    },
  },
}
</script>
