<template>
  <div ref="exportSettingEdit" class="export-setting-edit">
    <base-dialog
      ref="dialogDeleteColumn"
      :title="$t('message.confirm')"
      message="この項目を削除しますか?"
      :cancel-text="$t('message.no')"
      :submit-text="$t('message.yes')"
      @submit="
        deleteExportLayout(selected_index)
        $refs.dialogDeleteColumn.close()
      "
    />

    <v-dialog v-model="dialogSetCustomField" max-width="720">
      <v-card class="sweeep-dialog">
        <v-card-title>
          <p>カスタムフォーマット設定</p>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-if="
              export_setting.layout_data.length &&
              export_setting.layout_data[selectedCustomFormat.index]
                .export_column_type == 2
            "
            ref="customFormatArea"
            v-model="selectedCustomFormat.item.text_format"
            style="white-space: pre-wrap; word-wrap: break-word"
            outlined
            dense
            filled
            label="カスタムコードを指定"
            :hint="
              gethint(
                selectedCustomFormat.item.text_format,
                customFormatErrors[selectedCustomFormat.index]
              )
            "
            @input="edit.count += 1"
          />
          <v-row no-gutters>
            <v-autocomplete
              v-model="selectedCustomFormat.item.export_format_field_id"
              :items="export_field_objects"
              label="データ項目を選択"
              outlined
              filled
              dense
              hide-details
              clearable
              item-text="display_name"
              item-value="id"
              @input="selectedCustomFormat.item.show_all = true"
            />
            <v-btn
              class="ml-3"
              @click="setCustomColumns(selectedCustomFormat.item)"
            >
              カスタムコードに配置
            </v-btn>
          </v-row>
          <v-row
            v-if="
              export_setting.layout_data.length &&
              export_setting.layout_data[selectedCustomFormat.index]
                .export_column_type == 2 &&
              isDateField(selectedCustomFormat.index)
            "
            class="mt-3"
            no-gutters
          >
            <v-autocomplete
              v-model="selectedCustomFormat.item.date_format_id"
              :items="date_format_objects"
              label
              outlined
              filled
              dense
              hide-details
              placeholder="日付形式"
              clearable
              item-text="name"
              item-value="id"
            />
          </v-row>
          <div class="mt-3">
            <v-icon @click="menu = !menu"> feather-info </v-icon>
            設定の方法について
          </div>
          <div
            v-show="menu"
            class="mt-2 sweeep-export-settings-custom-format-hint"
          >
            <v-card>
              <v-card-text>
                <div>
                  =関数名()
                  を記述することで関数の実行が行えます。sweeepのデータ項目と併用してください。<br />
                  四則演算(+, -, *, /)を利用することもできます。
                </div>
                <p class="mt-3 mb-1 fw-bold">IF</p>
                <p class="pl-3">
                  条件文に応じて値を返します。 =IF(条件文, "真の場合の値",
                  "偽の場合の値")
                  <br />(例)=IF(%伝票No.% = 101, "101番", "101番ではない")'
                </p>
                <p class="mt-3 mb-1 fw-bold">AND</p>
                <p class="pl-3">
                  複数条件が全て正の場合Trueを返します。 =IF(AND(条件文1,
                  条件文2), "真の場合の値", "偽の場合の値")
                  <br />(例)=IF(AND(%伝票No.% = 101, %支払日付|1% > "2020-05-01"
                  ), "101番かつ支払日付05/01以降", "")
                </p>
                <p class="mt-3 mb-1 fw-bold">OR</p>
                <p class="pl-3">
                  複数条件のいずれかが正の場合Trueを返します。 =IF(OR(条件文1,
                  条件文2), "真の場合の値", "偽の場合の値")
                  <br />(例)=IF(OR(%伝票No.% = 101, %支払日付|1% > "2020-05-01"
                  ), "101番または支払日付05/01以降", "")
                </p>
                <p class="mt-3 mb-1 fw-bold">TEXT</p>
                <p class="pl-3">
                  数字列、日付の形式を変換します。 =Text(変換対象文字列,
                  変換形式)
                  <br />(例)=TEXT(%請求金額%, "¥0,0.0"), =Text(%伝票日付|1%,
                  "ggg e年")
                </p>
                <p class="mt-3 mb-1 fw-bold">LEFT, LEFTB</p>
                <p class="pl-3">
                  文字列の左側から指定した文字数(バイト数)を抽出します。
                  =LEFT(文字列, 文字数) =LEFTB(文字列, バイト数)
                  <br />(例)=LEFT(%発行元会社名%, 3)
                </p>
                <p class="mt-3 mb-1 fw-bold">MID</p>
                <p class="pl-3">
                  指定の文字列から任意の位置より文字数を抽出します。
                  =Mid(文字列, 開始位置, 文字数)
                  <br />(例)=MID(%発行元会社名%, 1, 1)
                </p>
                <p class="mt-3 mb-1 fw-bold">ASC</p>
                <p class="pl-3">
                  全角文字を半角文字に変換します。 =ASC(文字列)
                  <br />(例)=ASC(%発行元会社名%)
                </p>
                <p class="mt-3 mb-1 fw-bold">JIS</p>
                <p class="pl-3">
                  半角文字を全角文字に変換します。 =JIS(文字列)
                  <br />(例)=JIS(%発行元会社名%)
                </p>
                <p class="mt-3 mb-1 fw-bold">CONCAT</p>
                <p class="pl-3">
                  文字列を結合します。 =CONCAT(文字列1, 文字列2, ...文字列3,4,5)
                  <br />(例)=CONCAT(%承認ユーザ名%, %管理メモ%)
                </p>
                <p class="mt-3 mb-1 fw-bold">EOMONTH</p>
                <p class="pl-3">
                  開始日から指定された月数後の月の最終日を返します。
                  =EOMONTH(開始日, 月)
                  <br />(例)=EOMONTH(%伝票日付|1%, 2), =EOMONTH(%伝票日付|2%,
                  -3)
                </p>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default darken-1"
            @click.native="dialogSetCustomField = false"
          >
            閉じる
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            @click.native="
              setCostomFormatText(
                selectedCustomFormat.item,
                selectedCustomFormat.index
              )
            "
          >
            設定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-toolbar flat dark height="50">
        仕訳エクスポート設定
        <v-spacer />
        <v-icon @click="onClick('cancel')" v-text="'feather-x'" />
      </v-toolbar>
      <v-card-text style="height: calc(100vh - 100px)">
        <v-row class="px-3">
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="export_setting.export_format.name"
              label="エクスポート名称"
              filled
              outlined
              dense
              :disabled="!addMode.flag"
              @change="edit.flag = true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              v-model="export_setting.export_type"
              label="貸借様式"
              filled
              outlined
              dense
              :items="export_type_objects"
              item-text="name"
              validate-on-blur
              return-object
              :disabled="!addMode.flag"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
              v-model="export_setting.export_format.header_row_size"
              label="ヘッダー行数"
              filled
              outlined
              dense
              :items="[1, 2, 3]"
              validate-on-blur
              :disabled="!addMode.flag"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
              v-model="export_setting.export_format.footer_row_size"
              label="フッター行数"
              filled
              outlined
              dense
              :items="[0, 1, 2]"
              validate-on-blur
              :disabled="!addMode.flag"
            />
          </v-col>
        </v-row>

        <div v-if="!enableShowTable" class="mx-3">
          貸借様式を選択してください
        </div>

        <div v-else>
          <!-- 個別編集機能 後日実装
          <v-radio-group
            v-model="export_setting.displaySetting"
            row
            class="ml-4"
          >
            <v-radio
              color="blue"
              label="表示"
              :value="true"
            />
            <v-radio
              color="blue"
              label="非表示"
              :value="false"
            />
          </v-radio-group> -->

          <v-toolbar flat height="50">
            <v-toolbar-title class="font-sm mr-4">
              <span v-if="editableData"> ヘッダ行をクリックして設定 </span>
              <span v-else>
                デフォルトのエクスポート設定はレイアウトを変更できません。
              </span>
            </v-toolbar-title>
            <s-chip-dark textColor="gray-900" color="gray-300" class="px-2">
              表示データはサンプルです
            </s-chip-dark>
          </v-toolbar>

          <v-simple-table
            class="fixed-header mx-3"
            fixed-header
            :height="'calc(100vh - 350px)'"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(item, index) in sampleData.header"
                    :key="`sampleDataHeader${index}`"
                    style="min-width: 100px; cursor: pointer"
                    @click.stop="showMenu(index)"
                  >
                    <v-menu
                      v-model="item.menu.display"
                      :position-x="item.menu.pos_x"
                      :position-y="item.menu.pos_y"
                      absolute
                      offset-y
                      transition="slide-y-transition"
                      :close-on-click="!dialogSetCustomField"
                      :close-on-content-click="false"
                      nudge-bottom="10"
                      nudge-left="10"
                      max-width="330"
                      z-index="7"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          :id="'menu_' + index"
                          v-bind="attrs"
                          class="menu-active"
                          v-on="on"
                        >
                          <template v-for="rowNum in headerRowNums">
                            {{ item.text[rowNum] }}
                          </template>
                        </div>
                      </template>

                      <v-card class="sweeep-popover">
                        <v-card-title>
                          <span class="fs-12">No.{{ index + 1 }}</span>
                          <v-spacer />
                          <v-btn
                            v-tooltip="'新規列を右に追加'"
                            icon
                            dark
                            @click="addExportLayout(index)"
                          >
                            <v-icon x-small> feather-plus </v-icon>
                          </v-btn>
                          <v-btn
                            v-tooltip="'列を左へ移動'"
                            icon
                            dark
                            v-bind="{ disabled: index == 0 }"
                            @click="changeExportLayout('up', index)"
                          >
                            <v-icon x-small> feather-arrow-left </v-icon>
                          </v-btn>
                          <v-btn
                            v-tooltip="'列を右へ移動'"
                            icon
                            dark
                            v-bind="{
                              disabled:
                                index + 1 == export_setting.layout_data.length,
                            }"
                            @click="changeExportLayout('down', index)"
                          >
                            <v-icon x-small> feather-arrow-right </v-icon>
                          </v-btn>
                          <v-btn
                            v-tooltip="'列を削除'"
                            icon
                            dark
                            v-bind="{
                              disabled: export_setting.layout_data.length == 1,
                            }"
                            @click="deleteExportLayout(index, 'dialog')"
                          >
                            <v-icon x-small> feather-trash-2 </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          ヘッダ
                          <template v-for="hIndex in headerRowNums">
                            <v-text-field
                              :key="`header${hIndex}`"
                              v-model="
                                export_setting.layout_data[index].header_name[
                                  hIndex
                                ]
                              "
                              hide-details
                              label="列名"
                              filled
                              outlined
                              dense
                              class="my-2"
                              @input="edit.count += 1"
                            />
                          </template>
                          <v-radio-group
                            v-model="
                              export_setting.layout_data[index].header_type
                            "
                            mandatory
                            hide-details
                          >
                            <v-radio
                              label="列名をヘッダとして出力"
                              :value="0"
                            />
                            <v-radio
                              v-tooltip="'この列のヘッダ文字を空欄にします。'"
                              label="ヘッダにはブランクを出力（カンマあり）"
                              :value="1"
                            />
                            <v-radio
                              v-tooltip="
                                '項目名の前のカンマを除去します。<br>出力データの列とずれるので使用時はご注意ください。'
                              "
                              label="ヘッダにはブランクを出力（カンマなし）"
                              :value="2"
                            />
                          </v-radio-group>

                          <div
                            v-if="export_setting.export_format.footer_row_size"
                          >
                            <v-divider class="my-4" />
                            フッタ
                            <template
                              v-for="fIndex in export_setting.export_format
                                .footer_row_size"
                            >
                              <v-text-field
                                :key="fIndex"
                                v-model="
                                  export_setting.layout_data[index].footer_name[
                                    fIndex - 1
                                  ]
                                "
                                hide-details
                                filled
                                outlined
                                dense
                                label="フッタ行の表示名"
                                class="my-2"
                                @input="edit.count += 1"
                              />
                            </template>
                          </div>

                          <v-divider class="my-4" />
                          出力データ
                          <v-radio-group
                            v-model="
                              export_setting.layout_data[index]
                                .export_column_type
                            "
                            mandatory
                            row
                          >
                            <v-radio
                              label="項目選択"
                              :value="0"
                              @change="changeFormatType(0, index)"
                            />
                            <v-radio
                              label="固定値"
                              :value="1"
                              @change="changeFormatType(1, index)"
                            />
                            <v-radio
                              label="カスタム"
                              :value="2"
                              @change="changeFormatType(2, index)"
                            />
                          </v-radio-group>

                          <v-autocomplete
                            v-show="
                              export_setting.layout_data[index]
                                .export_column_type == 0
                            "
                            v-model="
                              export_setting.layout_data[index]
                                .export_format_field_id
                            "
                            :items="export_field_objects"
                            hide-details
                            filled
                            outlined
                            dense
                            label="データ種類"
                            clearable
                            item-text="display_name"
                            item-value="id"
                            @input="edit.count += 1"
                          />

                          <v-autocomplete
                            v-show="
                              export_setting.layout_data[index]
                                .export_column_type == 0 && isDateField(index)
                            "
                            v-model="
                              export_setting.layout_data[index].date_format_id
                            "
                            :items="date_format_objects"
                            hide-details
                            filled
                            outlined
                            dense
                            class="mt-3"
                            label="日付形式"
                            clearable
                            item-text="name"
                            item-value="id"
                            @input="edit.count += 1"
                          />

                          <v-text-field
                            v-show="
                              export_setting.layout_data[index]
                                .export_column_type == 1
                            "
                            v-model="
                              export_setting.layout_data[index].fix_value
                            "
                            hide-details
                            filled
                            outlined
                            dense
                            label="固定値を入力"
                            @input="edit.count += 1"
                          />

                          <v-text-field
                            v-show="
                              export_setting.layout_data[index]
                                .export_column_type == 2
                            "
                            v-model="
                              export_setting.layout_data[index].text_format
                            "
                            hide-details
                            filled
                            outlined
                            dense
                            readonly
                            @click="
                              openCustomFormatDialog(
                                export_setting.layout_data[index],
                                index
                              )
                            "
                          />

                          <v-autocomplete
                            v-show="
                              export_setting.layout_data[index]
                                .export_column_type == 1 ||
                              ((export_setting.layout_data[index]
                                .export_column_type == 0 ||
                                export_setting.layout_data[index]
                                  .export_column_type == 2) &&
                                isSummaryField(index))
                            "
                            v-model="export_setting.layout_data[index].show_all"
                            :items="[
                              { val: true, name: 'すべて表示' },
                              { val: false, name: '先頭明細のみ表示' },
                            ]"
                            hide-details
                            filled
                            outlined
                            dense
                            class="mt-3"
                            label="表示モード"
                            clearable
                            item-text="name"
                            item-value="val"
                            @input="edit.count += 1"
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in sampleData.data"
                  :key="`sampleData${index}`"
                >
                  <td
                    v-for="(value, valueIndex) in item"
                    :key="`row${index}-col${valueIndex}`"
                  >
                    {{ value }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <template v-for="f in computedFooter">
                    <td>
                      <template v-for="h in f.length">
                        {{ f[h - 1] }}<br />
                      </template>
                    </td>
                  </template>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </div>

        <v-toolbar flat>
          <v-spacer />
          <v-btn @click="onClick('cancel')">
            {{ $t('message.modal_cancel_btn') }}
          </v-btn>
          <v-btn
            color="primary"
            class="ml-2"
            :disabled="
              (addMode.flag && !export_setting.export_format.name) ||
              !export_setting.export_type.id ||
              (!addMode.flag && !editableData)
            "
            @click="addMode.flag ? onClick('create') : onClick('update')"
            v-text="addMode.flag ? '追加' : '更新'"
          />
        </v-toolbar>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    edit: null,
    addMode: null,
    export_format_objects: null,
    export_type_objects: null,
    export_field_objects: null,
    date_format_objects: null,
    headerRowNums: Array,
    customFormatErrors: Array,
    createExportFormat: Function,
    updateExportFormat: Function,
    getExportFormatObjects: Function,
    requestUpdateDisplaySettings: Function,
    export_setting: Object,
    sampleData: Object,
  },
  data() {
    var t = this
    return {
      dialog: false,
      selected_preset_id: '',
      format_name: '',
      new_preset: false,
      menu: false,
      field_list: [],
      default_field_list: [],
      format_preset_list: [],
      selected_field_list: [],
      inputRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          t.$t('message.form_input_required'),
        (v) => v.length <= 50 || '50' + t.$t('message.character_max_length'),
      ],
      select_status: false,
      dialogSetCustomField: false,
      statementRules: [],
      dateFieldIDs: [5, 6, 20],
      selectedCustomFormat: {
        index: 0,
        item: {
          date_format_id: null,
          export_column_type: 0,
          export_format_field_id: null,
          export_format_id: null,
          fix_value: '',
          footer_name: null,
          header_name: null,
          id: null,
          show_all: null,
          header_type: 0,
          text_format: '',
        },
      },
      editingColumnIndex: 0,
      editingColumnItem: {},
      change_export_type_flag: false,
      menu_position: { x: 100, y: 100 },
      displaysetting: true,
      addRightEnd: false,
    }
  },
  computed: {
    editableData() {
      if (this.export_setting.export_format.organization) {
        return true
      } else {
        return false
      }
    },
    enableShowTable() {
      try {
        let data = this.sampleData
        if (data.data) {
          if (data.data.length > 0) {
            return true
          }
        }
        return false
      } catch (e) {
        console.log(e)
      }
      return false
    },
    layoutData: function () {
      return function (index) {
        return this.layout_data[index]
      }
    },
    filteredList() {
      var list = this.field_list.filter((post) => {
        return post.name.toLowerCase().includes(this.search.toLowerCase())
      })
      return list
    },
    computedFooter() {
      if (this.export_setting.export_format.footer_row_size === 0) {
        return []
      }
      var footerData = []
      this.sampleData.footer.forEach((v) => {
        let cellData = []
        for (
          let i = 0;
          i < this.export_setting.export_format.footer_row_size;
          i++
        ) {
          cellData.push(v.text[i])
        }
        footerData.push(cellData)
      })
      return footerData
    },
  },
  watch: {
    editingColumnIndex: function (newVal) {
      this.editingColumnItem = this.export_setting.layout_data[newVal]
    },
    dialogSetCustomField: function (val) {
      if (!val) {
        this.initCostomFormat()
      }
    },
    sampleData: function () {
      if (this.addRightEnd) {
        this.scrollLastColumn()
        this.addRightEnd = false
      }
    },
  },
  methods: {
    showMenu(index) {
      var menu = this.sampleData.header[index].menu
      menu.display = false
      var menuId = 'menu_' + index
      var targetElement = document.getElementById(menuId)
      var clientRect = targetElement.getBoundingClientRect()

      menu.pos_x = clientRect.left
      menu.pos_y = clientRect.bottom

      this.$nextTick(() => {
        menu.display = true
      })
    },
    closeMenu(index) {
      this.sampleData.header[index].menu.display = false
    },
    open() {
      this.dialog = true
      this.change_export_type_flag = false
    },
    close() {
      this.dialog = false
      this.change_export_type_flag = false
    },
    onClick(type) {
      //個別編集機能 後日実装
      // let params =  {
      //   organization_id: this.$store.getters.getOrganizationID,
      //   export_format_ids: [this.export_setting.export_format.id],
      //   display_setting: this.export_setting.displaySetting
      // }
      switch (type) {
        case 'update':
          // 個別編集機能 後日実装
          // this.updateExportFormat().then(() => {
          //   this.requestUpdateDisplaySettings(params).then( () => {
          //     this.getExportFormatObjects()
          //   })
          // })
          this.updateExportFormat().then(() => {
            this.getExportFormatObjects()
          })
          break
        case 'create':
          this.createExportFormat().then(() => {
            this.getExportFormatObjects()
          })
          break
        case 'cancel':
          break
      }
      this.$emit('onClick')
      this.close()
    },
    isDateField(i) {
      var field_id = this.export_setting.layout_data[i].export_format_field_id
      if (this.dateFieldIDs.indexOf(field_id) !== -1) {
        return true
      } else {
        return false
      }
    },
    isSummaryField(i) {
      try {
        var field_id = this.export_setting.layout_data[i].export_format_field_id
        var field_obj = this.export_field_objects.find(
          (item) => item.id == field_id
        )
        var is_summary = field_obj.is_summary
        if (is_summary) {
          return true
        } else {
          return false
        }
      } catch (e) {
        return false
      }
    },
    initFixValue(i) {
      this.export_setting.layout_data[i].fix_value = ''
      this.edit.count += 1
    },
    initTextFormat(i) {
      this.export_setting.layout_data[i].text_format = ''
      this.edit.count += 1
    },
    initFieldId(i) {
      this.export_setting.layout_data[i].export_format_field_id = null
      this.export_setting.layout_data[i].date_format = ''
      this.edit.count += 1
    },
    initCostomFormat() {
      this.selectedCustomFormat = {
        index: 0,
        item: {
          date_format_id: null,
          export_column_type: 0,
          export_format_field_id: null,
          export_format_id: null,
          fix_value: '',
          footer_name: null,
          header_name: null,
          id: null,
          show_all: null,
          header_type: 0,
          text_format: '',
        },
      }
    },
    addExportLayout(index) {
      try {
        this.closeMenu(index)
        let add_data = {
          export_format_field: null,
          fix_value: '',
          text_format: '',
          date_format: null,
          header_name: ['未指定', '', '', '', ''],
          show_all: true,
          header_type: 0,
          footer_name: [],
        }
        if (index + 1 === this.export_setting.layout_data.length) {
          this.addRightEnd = true
        }
        this.export_setting.layout_data.splice(index + 1, 0, add_data)
        this.edit.count += 1
      } catch (e) {
        console.log(e)
      }
    },
    scrollLastColumn() {
      this.$nextTick(() => {
        const table = this.$refs.exportSettingEdit.getElementsByClassName(
          'v-data-table__wrapper'
        )[0]
        table.scrollLeft = table.scrollWidth
      })
    },
    deleteExportLayout(index, action = '') {
      try {
        if (action == 'dialog') {
          this.selected_index = index
          this.$refs.dialogDeleteColumn.open()
        } else {
          this.closeMenu(index)
          this.export_setting.layout_data.splice(this.selected_index, 1)
        }
        this.edit.count += 1
      } catch (e) {
        console.log(e)
      }
    },
    changeExportLayout(action, index) {
      try {
        if (index > 0 && action == 'up') {
          this.closeMenu(index)
          let layout_data_above = this.export_setting.layout_data[index - 1]
          let layout_data_this = this.export_setting.layout_data[index]
          this.export_setting.layout_data.splice(
            index - 1,
            2,
            layout_data_this,
            layout_data_above
          )
          this.editingColumnIndex -= 1
          this.edit.count += 1
          this.showMenu(index - 1)
        } else if (index < this.export_setting.layout_data.length - 1) {
          this.closeMenu(index)
          let layout_data_this = this.export_setting.layout_data[index]
          let layout_data_bottom = this.export_setting.layout_data[index + 1]
          this.export_setting.layout_data.splice(
            index,
            2,
            layout_data_bottom,
            layout_data_this
          )
          this.editingColumnIndex += 1
          this.edit.count += 1
          this.showMenu(index + 1)
        }
      } catch (e) {
        console.log(e)
      }
    },
    insertText(text) {
      const customFormatArea = this.$refs.customFormatArea.$refs.input
      customFormatArea.focus()
      document.execCommand('insertText', false, text)
    },
    setCustomColumns(item) {
      if (!item.export_format_field_id) {
        return
      }
      let export_field = this.export_field_objects.find((v) => {
        return v.id === item.export_format_field_id
      })
      const field_id = item.export_format_field_id
      let textToInsert = ''
      if (item.text_format == null) {
        item.text_format = ''
      }
      if (this.dateFieldIDs.indexOf(field_id) !== -1) {
        let date_format_id = item.date_format_id ? item.date_format_id : 1
        textToInsert = `%${export_field.display_name}|${date_format_id}%`
      } else {
        textToInsert = `%${export_field.display_name}%`
      }
      try {
        this.insertText(textToInsert)
      } catch (e) {
        item.text_format += textToInsert
      }
    },
    openCustomFormatDialog(item, index) {
      this.dialogSetCustomField = true
      this.selectedCustomFormat.index = index
      this.selectedCustomFormat.item = { ...item }
    },
    gethint(text, errorString) {
      //
      try {
        if (errorString) {
          return `* ${errorString}`
        }
        if (!text) {
          return null
        }
        /* let match = text.match(/=\S{1,}\(/g)
        //最後にマッチする物
        // let statement = match ? match[match.length-1] : ''
        if (statement === '=IF(') {
          return '=IF(条件文, "真の場合の値", "偽の場合の値")\
                  \n(例)=IF(%伝票No.% = 101, "101番", "101番ではない")'
        }*/
        return null
      } catch (e) {
        console.log(e)
      }
    },
    setCostomFormatText(item, index) {
      this.export_setting.layout_data[index] = Object.assign({}, item)
      this.dialogSetCustomField = false
      this.edit.count += 1
    },
    changeFormatType(formatType, index) {
      if (formatType === 2) {
        this.initFixValue(index)
      } else {
        this.initFixValue(index)
        this.initTextFormat(index)
        this.dialogSetCustomField = false
      }
    },
  },
}
</script>
