<template>
  <v-card flat class="pa-3">
    <app-section-loader :status="loader" />

    <v-dialog v-model="dialogOption" max-width="450">
      <v-card v-if="deleteInvitation" class="sweeep-dialog">
        <v-card-title>
          {{ $t('message.confirm') }}
        </v-card-title>
        <v-card-text>
          {{ $t('message.user_modal_cancel_invitation') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click.native="dialogOption = false"
            v-text="$t('message.modal_cancel_btn')"
          />
          <v-btn
            color="primary"
            @click="
              deleteUser()
              dialogOption = false
            "
            v-text="$t('message.user_modal_delete')"
          />
        </v-card-actions>
      </v-card>
      <v-card v-else class="sweeep-dialog">
        <v-card-title>
          {{ $t('message.confirm') }}
        </v-card-title>
        <v-card-text>
          ユーザにメールを再送信してもよろしいですか？
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click.native="dialogOption = false"
            v-text="$t('message.modal_cancel_btn')"
          />
          <v-btn
            color="primary"
            @click="
              resendInvitationEmail()
              dialogOption = false
            "
            v-text="'送信'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>

      <v-data-table
        v-model="selected"
        class="my-2"
        :headers="headers"
        :items="data"
        :search="search"
        item-key="id"
        :footer-props="footerProps"
        fixed-header
        height="calc(100vh - 300px)"
      >
        <template #item.valid_until="{ item }">
          {{ getValidUntilFormated(item.valid_until) }}
        </template>

        <template #item.user_role_name="{ item }">
          {{ item.user_role_name }}:
          {{ !item.user_role_code ? 'コードなし' : item.user_role_code }}
        </template>

        <template #item.action="{ item }">
          <div class="d-flex justify-space-around">
            <v-btn
              color="default"
              small
              rounded
              @click="selectUser(item, true)"
            >
              {{ $t('message.user_table_cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              small
              rounded
              @click="selectUser(item, false)"
            >
              招待メール再送信
            </v-btn>
          </div>
        </template>

        <template #no-results>
          <v-alert :value="true" color="info" icon="warning">
            {{ $t('message.noResult') }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AppConfig from 'Constants/AppConfig'
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    roleList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    hasOwnerRole: {
      type: Boolean,
      default: false,
    },
    roleTooltip: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      dialogOption: false,
      deleteInvitation: false,
      resendEmail: false,
      loader: false,
      search: '',
      selected: [],
      headers: [],
      items: [],
      selectedUser: [],
      pagination: AppConfig.pagination,
      footerProps: {
        'items-per-page-text': '表示件数',
        'items-per-page-options': AppConfig.pagination,
      },
    }
  },
  created() {
    try {
      this.headers = [
        { text: 'コード', value: 'code' },
        { text: this.$t('message.user_table_invited_email'), value: 'email' },
        {
          text: this.$t('message.user_table_valid_until'),
          value: 'valid_until',
        },
        {
          text: this.$t('message.user_table_role_name'),
          value: 'user_role_name',
        },
        { text: this.$t('message.user_table_invited_by'), value: 'invited_by' },
        { width: 200, text: '', value: 'action', sortable: false },
      ]
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    deleteUser() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const apiUrl = this.$store.getters.apiUserInviteFunction
        const user_id = this.$store.getters.getUserID
        const user_role_id = this.$store.getters.getUserRole
        const data = {
          id: this.selectedUser.id,
          user_id: user_id,
          user_role_id: user_role_id,
          auth_token: auth_token,
        }
        this.$store
          .dispatch('cancelUserInvite', {
            data,
            apiUrl,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              let index = this.data.findIndex(
                (x) => x.id == this.selectedUser.id
              )
              this.data.splice(index, 1)
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    selectUser(data, isDelete) {
      try {
        this.deleteInvitation = isDelete
        this.resendEmail = !isDelete
        this.dialogOption = true
        this.selectedUser = data
      } catch (e) {
        console.log(e)
      }
    },
    resendInvitationEmail() {
      const url = this.$store.getters.apiCheckUserInviteUrl
      const data = this.selectedUser
      const hostUrl =
        process.env.VUE_APP_HOSTURL_EMAIL + 'session/invite/confirm/'
      axios
        .put(url, { data, hostUrl }, this.$store.getters.getRequestHeaders)
        .then((response) => {
          if (response.data.status === 'success') {
            Snackbar.success('招待メールを送信しました')
          } else if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            Snackbar.error('招待は有効になっています')
          }
        })
    },
    getValidUntilFormated(date) {
      var formatedDate = date.slice(0, 10) + ' ' + date.slice(11, 19)
      return formatedDate
    }
  },
}
</script>
