<template>
  <v-row no-gutters>
    <v-col cols="2">
      <div class="pt-6">
        {{ definition[name] }}
      </div>
    </v-col>
    <v-col cols="10">
      <v-text-field
        v-model="form.maxlen[name]"
        type="number"
        :suffix="suffix"
        min="1"
        max="255"
        class="fs-10"
        hide-details
        style="width: 100px"
        :rules="[
          (v) => (!!v && v > 0) || $t('message.form_input_required_above_zero'),
          (v) =>
            (!!v && v < 256) || $t('message.form_input_required_under_256'),
        ]"
        required
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    form: Object,
    definition: Object,
    name: null,
    suffix: String,
  },
}
</script>
