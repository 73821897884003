<template>
  <v-container fluid pa-0>
    <v-form
      ref="plan"
      class="pa-4 mb-5"
      lazy-validation
      style="max-width: 680px"
    >
      <v-card class="sweeep-dialog">
        <v-card-title> プラン設定 </v-card-title>
        <v-card-text class="pa-4">
          <v-text-field
            label="プラン名"
            placeholder=" "
            disabled
            class="mb-2"
          />
          <v-text-field
            label="利用開始日"
            placeholder=" "
            disabled
            class="mb-2"
          />
          <v-text-field
            label="次回決済日"
            placeholder=" "
            disabled
            class="mb-2"
          />
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
export default {}
</script>
