<template>
  <v-card outlined tile class="w-100">
    <vue-perfect-scrollbar>
      <v-card-text class="h-without-header-footer">
        <base-dialog
          ref="dialogGenerateEmail"
          :title="$t('message.confirm')"
          message="すでに発行済みのメールアドレスは使用できなくなりますが再発行してもよろしいですか？"
          :cancel-text="$t('message.no')"
          :submit-text="$t('message.yes')"
          @submit="
            generateEmail()
            $refs.dialogGenerateEmail.close()
          "
        />

        <v-form
          ref="form"
          class="pa-4 mb-5"
          lazy-validation
          style="max-width: 600px"
        >
          <v-card class="sweeep-dialog">
            <v-card-title>メール取り込み</v-card-title>
            <v-card-text class="pa-4 justify-center">
              <div
                v-if="loader.getOrganizationEmail == true"
                class="text-xs-center"
              >
                <v-progress-circular indeterminate color="primary" />
              </div>

              <v-layout v-else row wrap>
                <v-switch
                  v-model="organizationEmail.allowEmailUpload"
                  v-tooltip="
                    'オフにするとメール取り込み機能を停止することができます'
                  "
                  class="px-3 mb-4"
                  color="primary"
                  hide-details
                  label="メールでの取込みを許可する"
                  style="width: 250px"
                />
                <v-flex
                  v-if="organizationEmail.allowEmailUpload"
                  xs12
                  class="px-3"
                >
                  <div class="fs-14 mb-3 fw-bold">1. メールアドレスの発行</div>
                  <div class="fs-12 mb-3 px-3">
                    次のボタンを押して取込用のメールアドレスを発行します。
                  </div>
                  <v-btn
                    class="mb-3"
                    color="primary"
                    :loading="loader.generateEmail"
                    @click="checkGenerateEmail()"
                  >
                    取込用メールアドレス発行
                  </v-btn>
                  <v-divider />

                  <div class="fs-14 my-3 fw-bold">2. 取込設定</div>
                  <div class="fs-12 mb-3 px-3">
                    メール取込時のPDFファイルの読み取り方法を選択してください。（PDFを画面からアップロードする場合は、その都度読み取り方法を指定できます。）
                  </div>
                  <v-radio-group
                    v-model="emailUploadSetting.multiple_invoice"
                    class="px-3"
                  >
                    <v-radio
                      v-tooltip="'ファイル数分の仕訳が作成されます'"
                      color="primary"
                      label="ファイル単位で読み取り（1ファイル＝1請求書）"
                      :value="true"
                    />
                    <v-flex
                      v-show="emailUploadSetting.multiple_invoice"
                      xs6
                      class="pl-4 pt-1"
                    >
                      <v-autocomplete
                        v-model="emailUploadSetting.max_page"
                        v-tooltip="'読み取り対象ページは最大5ページです。'"
                        :items="page_items"
                        item-text="name"
                        item-value="page"
                        label="読み取り対象ページ"
                        outlined
                        dense
                        filled
                      />
                    </v-flex>
                    <v-radio
                      v-tooltip="'ページ数分の仕訳が作成されます'"
                      color="primary"
                      label="ページを分割して読み取り（1ページ＝1請求書）"
                      :value="false"
                    />
                  </v-radio-group>
                  <v-divider />

                  <div class="fs-14 my-3 fw-bold">
                    3. 請求書データをメール送付
                  </div>
                  <div class="fs-12 mt-3 px-3">
                    請求書をメールに添付して、こちらのアドレスへ送信ください。
                  </div>
                  <v-text-field
                    class="px-3 mt-3 mb-0"
                    label=""
                    :value="organizationEmail.email"
                    append-icon="zmdi-copy"
                    outlined
                    dense
                    filled
                    @click.stop.prevent="writeToClipboard()"
                  />
                  <input
                    id="organization-email"
                    type="hidden"
                    :value="organizationEmail.email"
                  />
                  <v-divider />

                  <div class="fs-14 my-3 fw-bold">4. 送信元ドメイン制限</div>
                  <div class="fs-12 mb-3 px-3">
                    送信元ドメインの制御方法を選択してください。
                  </div>
                  <v-radio-group
                    v-model="emailUploadSetting.permission_sender"
                    class="px-3"
                  >
                    <v-radio
                      color="primary"
                      label="sweeepユーザのみ"
                      :value="1"
                    />
                    <v-radio
                      color="primary"
                      label="指定ドメインを許可"
                      :value="2"
                    />
                    <div v-if="emailUploadSetting.permission_sender == 2">
                      <div class="mb-4">
                        <template
                          v-for="(
                            item, i
                          ) in emailUploadSetting.allowed_email_domains"
                        >
                          <v-row :key="i" no-gutters>
                            <v-col cols="9" class="pl-4 pt-1">
                              <v-text-field
                                v-model="
                                  emailUploadSetting.allowed_email_domains[i]
                                "
                                label="ドメイン名"
                                dense
                                outlined
                                filled
                                hide-details
                                class="mt-2"
                              />
                            </v-col>
                            <v-col cols="3" class="pt-4">
                              <v-btn
                                class="ml-4"
                                small
                                @click="deleteDomain(i)"
                              >
                                削除
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </div>
                      <div class="mb-3">
                        <v-btn
                          icon
                          small
                          color="primary"
                          class="ml-4"
                          @click="addDomain"
                        >
                          <s-icon
                            icon="feather-plus"
                            color="current"
                            size="xl"
                          />
                        </v-btn>
                      </div>
                    </div>
                    <v-radio
                      color="primary"
                      label="全てのメールを許可"
                      :value="3"
                    />
                  </v-radio-group>
                  <v-divider />

                  <div class="fs-14 mt-3 fw-bold">メール取込解除</div>
                  <div class="fs-12 my-3 px-3">
                    メール取込を無効にしたい場合は一番上のスイッチをオフにして更新してください。
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
    </vue-perfect-scrollbar>

    <v-toolbar height="50">
      <v-btn
        small
        color="primary"
        @click="
          updateEmailUploadSetting()
          save()
        "
      >
        更新
      </v-btn>
    </v-toolbar>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="success"
      class="mt-4"
      top
    >
      <p class="ma-0">
        <i class="ti-thumb-up mr-2" />
        {{ copiedMessage }}
      </p>
    </v-snackbar>
  </v-card>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import Axios from 'axios'
export default {
  data() {
    return {
      copiedMessage: '',
      snackbar: false,
      dialog: { generateEmail: false },
      loader: {
        generateEmail: false,
        getOrganizationEmail: true,
      },
      organizationEmail: {
        allowEmailUpload: false,
        email: '',
      },
      emailUploadSetting: {
        multiple_invoice: '',
        max_page: '',
        permission_sender: '',
        allowed_email_domains: [],
      },
      page_items: [
        {
          page: 1,
          name: '1ページ目のみ',
        },
        {
          page: 2,
          name: '2ページ目まで',
        },
        {
          page: 3,
          name: '3ページ目まで',
        },
        {
          page: 4,
          name: '4ページ目まで',
        },
        {
          page: 5,
          name: '5ページ目まで',
        },
      ],
    }
  },
  mounted() {
    this.getOrganizationEmail()
    this.getEmailUploadSetting()
  },
  methods: {
    addDomain() {
      try {
        this.emailUploadSetting.allowed_email_domains.push('')
      } catch (e) {
        console.log(e)
      }
    },
    deleteDomain(index) {
      this.emailUploadSetting.allowed_email_domains.splice(index, 1)
      if (this.emailUploadSetting.allowed_email_domains.length == 0) {
        this.emailUploadSetting.allowed_email_domains.push('')
      }
    },
    writeToClipboard() {
      let emailToCopy = document.querySelector('#organization-email')
      emailToCopy.setAttribute('type', 'text')
      emailToCopy.select()
      try {
        var successful = document.execCommand('copy')
        this.copiedMessage = successful
          ? 'アドレスをコピーしました'
          : 'アドレスをコピーしてください'
        this.snackbar = true
      } catch (err) {
        this.copiedMessage = 'アドレスをコピーしてください'
      }
      emailToCopy.setAttribute('type', 'hidden')
    },
    checkGenerateEmail() {
      if (this.organizationEmail.email == '') {
        this.generateEmail()
      } else {
        this.$refs.dialogGenerateEmail.open()
      }
    },
    generateEmail() {
      try {
        this.loader.generateEmail = true
        Axios.put(
          this.$store.getters.apiOrganizationEmailUrl,
          { org_id: this.$store.getters.getOrganizationID },
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        ).then((response) => {
          if (response.data.status == 'success') {
            this.organizationEmail.email = response.data.data
            this.organizationEmail.allowEmailUpload = true
            this.save()
          } else {
            Snackbar.error(response.data.error)
          }
          this.loader.generateEmail = false
          console.log(response)
        })
      } catch (err) {
        console.log(err)
      }
    },
    getOrganizationEmail() {
      try {
        Axios.get(this.$store.getters.apiOrganizationEmailUrl, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { organization_id: this.$store.getters.getOrganizationID },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.organizationEmail.allowEmailUpload =
              response.data.data.allow_email_upload
            this.organizationEmail.email = response.data.data.email
            this.loader.getOrganizationEmail = false
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    save() {
      try {
        Axios.post(
          this.$store.getters.apiOrganizationEmailUrl,
          {
            allow_email_upload: this.organizationEmail.allowEmailUpload,
            email: this.organizationEmail.email,
            organization_id: this.$store.getters.getOrganizationID,
          },
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        ).then((response) => {
          if (response.data.status == 'success') {
            Snackbar.success(this.$t('message.success'))
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getEmailUploadSetting() {
      try {
        Axios.get(this.$store.getters.apiEmailUploadSetting, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: {
            organization_id: this.$store.getters.getOrganizationID,
          },
        }).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            this.emailUploadSetting = response.data.data
            if (this.emailUploadSetting.allowed_email_domains.length == 0) {
              this.emailUploadSetting.allowed_email_domains.push('')
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    updateEmailUploadSetting() {
      try {
        const data = {
          organization_id: this.$store.getters.getOrganizationID,
          multiple_invoice: this.emailUploadSetting.multiple_invoice,
          max_page: this.emailUploadSetting.max_page,
          permission_sender: this.emailUploadSetting.permission_sender,
          allowed_email_domains: this.emailUploadSetting.allowed_email_domains,
        }
        const apiUrl = this.$store.getters.apiEmailUploadSetting
        Axios.put(apiUrl, data, {
          headers: { Authorization: this.$store.getters.getAuthToken },
        }).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            Snackbar.success('成功しました')
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
