const userBankForm = {
  bank_name: '',
  bank_branch_name: '',
  bank_account_name: '',
  bank_account_type: '',
  bank_account_number: '',
  user_code: '',
  default: '',
}

export function getUserBankForm() {
  return JSON.parse(JSON.stringify(userBankForm))
}
