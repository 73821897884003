<template>
  <v-card flat height="calc(100vh - 50px)" class="pa-3">
    <app-section-loader :status="loader" />

    <base-dialog
      ref="dialogDelete"
      :title="$t('message.delete')"
      :message="$t('message.ip_delete_message')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.table_delete_btn')"
      @submit="
        deleteInstance()
        $refs.dialogDelete.close()
      "
    />

    <base-dialog
      ref="dialogAdd"
      :title="$t('message.add')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.modal_save_btn')"
      max-width="500"
      @submit="checkIp('add')"
    >
      <template v-slot:card-text>
        <v-form ref="form_add" v-model="valid">
          <v-text-field
            v-model="ip_address"
            label="IPアドレス"
            :rules="inputRules"
            required
            dense
            outlined
            filled
          />
          <v-text-field
            v-model="description"
            label="説明"
            :rules="inputRulesDescription"
            required
            dense
            outlined
            filled
          />
        </v-form>
      </template>
    </base-dialog>

    <base-dialog
      ref="dialogEdit"
      :title="$t('message.edit')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.modal_save_btn')"
      max-width="500"
      @submit="checkIp('edit')"
    >
      <template v-slot:card-text>
        <v-form ref="form_edit" v-model="valid_edit">
          <div v-show="selectedIsCurrentIp" class="pb-2">
            {{ $t('message.ip_edit_current_ip') }}
          </div>
          <v-text-field
            v-model="ip_address_edit"
            label="IPアドレス"
            :rules="inputRules"
            required
            dense
            outlined
            filled
            :disabled="selectedIsCurrentIp"
          />
          <v-text-field
            v-model="description_edit"
            label="説明"
            :rules="inputRulesDescription"
            required
            dense
            outlined
            filled
          />
        </v-form>
      </template>
    </base-dialog>

    <vue-perfect-scrollbar>
      <v-card-text>
        <v-container>
          <v-row>
            <div>
              <v-btn color="primary" dark @click.native.stop="openAddDialog()">
                <i class="ti-plus mr-2" />
                {{ $t('message.add') }}
              </v-btn>
            </div>

            <v-spacer />
            <div>
              <v-card class="elevation-0" color="#F1F4F8" max-width="200">
                <v-card-text class="subheading">
                  Current IP: {{ currentIp }}
                </v-card-text>
              </v-card>
            </div>
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="data"
          item-key="id"
          :footer-props="footerProps"
          fixed-header
          height="calc(100vh - 250px)"
        >
          <template #item.action="{ item }">
            <div class="d-flex justify-space-around">
              <v-btn
                small
                rounded
                color="primary"
                @click.native.stop="$refs.dialogEdit.open()"
                @click="selectInstance(item)"
              >
                {{ $t('message.table_edit_btn') }}
              </v-btn>
              <v-btn
                small
                rounded
                color=""
                @click.native.stop="$refs.dialogDelete.open()"
                @click="selectInstance(item)"
              >
                {{ $t('message.table_delete_btn') }}
              </v-btn>
            </div>
          </template>

          <template #no-data>
            <v-alert :value="true" color="info" icon="warning">
              {{ $t('message.noResult') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </vue-perfect-scrollbar>
  </v-card>
</template>

<script>
import AppConfig from 'Constants/AppConfig'
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

export default {
  data() {
    var t = this
    return {
      data: [],
      description: '',
      description_edit: '',
      headers: [],
      footerProps: {
        'items-per-page-text': '表示件数',
        'items-per-page-options': AppConfig.pagination,
      },
      inputRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          t.$t('message.form_input_required'),
        (v) => v.length <= 50 || '50' + t.$t('message.character_max_length'),
        (v) =>
          /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm.test(
            v
          ) == true || '例:192.168.1.1',
      ],
      inputRulesDescription: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) => v.length <= 50 || '50' + t.$t('message.character_max_length'),
      ],
      ip_address: '',
      ip_address_edit: '',
      loader: false,
      selectedInstance: '',
      selectedIndex: '',
      selectedIsCurrentIp: false,
      valid: false,
      valid_edit: false,
      currentIp: '',
    }
  },
  mounted() {
    try {
      this.loadData()
      this.headers = [
        { text: 'IPアドレス', value: 'ip_address' },
        { text: '説明', value: 'description' },
        { width: 160, text: '', value: 'action', sortable: false },
      ]
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    openAddDialog() {
      try {
        this.$refs.dialogAdd.open()
        this.$nextTick(function () {
          this.$refs.form_add.reset()
        })
      } catch (e) {
        console.log(e)
      }
    },
    addInstance() {
      try {
        if (this.$refs.form_add.validate()) {
          const data = {
            ip_address: this.ip_address,
            description: this.description,
          }
          this.$store
            .dispatch('addIpRestriction', { data })
            .then((response) => {
              if (response.data.status == 'success') {
                const toAdd = {
                  id: response.data.data.id,
                  ip_address: response.data.data.ip_address,
                  description: response.data.data.description,
                }
                this.$refs.dialogAdd.close()
                this.data.push(toAdd)
                this.ip_address = ''
                this.description = ''
                this.successNotify(this.$t('message.success'))
                this.$refs.form_add.resetValidation()
              }
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    checkIp(action) {
      try {
        let url = this.$store.getters.apiGetRestrictedIpUrl
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        axios
          .get(url, {
            headers: { Authorization: this.$store.getters.getAuthToken },
          })
          .then((response) => {
            if (response.data.db_status) {
              if (this.ip_address == this.currentIp) {
                this.addInstance()
              } else {
                this.errorNotify(this.$t('message.ip_err_first_list'))
              }
            } else {
              if (this.currentIp.length == 0) {
                this.errorNotify(this.$t('message.ip_err_fetch'))
              } else {
                if (action == 'add') {
                  this.addInstance()
                } else if (action == 'edit') {
                  this.editInstance()
                }
              }
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    loadData() {
      try {
        let url = this.$store.getters.apiIpRestrictionsUrl
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        axios
          .get(url, {
            headers: { Authorization: this.$store.getters.getAuthToken },
          })
          .then((response) => {
            if (response.data.error) {
              this.errorNotify(response.data.error)
            } else {
              this.data = response.data.list
              var apiUrl = this.$store.getters.apiGetIpUrl
              axios
                .get(apiUrl, {
                  headers: { Authorization: this.$store.getters.getAuthToken },
                })
                .then((response) => {
                  this.currentIp = response.data.ip
                })
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    deleteInstance() {
      try {
        let selectedIp = this.selectedInstance.ip_address
        if (this.data.length > 1 && selectedIp == this.currentIp) {
          //登録IPが複数ある場合はCurrentIPを消せないようにする
          this.errorNotify(this.$t('message.ip_err_delete_current_ip'))
        } else {
          const data = { id: this.selectedInstance.id }
          this.$store
            .dispatch('deleteIpRestriction', { data })
            .then((response) => {
              if (response.status == 'success') {
                this.data.splice(this.selectedIndex, 1)
                this.successNotify(this.$t('message.success'))
              }
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    editInstance() {
      try {
        if (this.$refs.form_edit.validate()) {
          const data = {
            id: this.selectedInstance.id,
            ip_address: this.ip_address_edit,
            description: this.description_edit,
          }
          this.$store
            .dispatch('editIpRestriction', { data })
            .then((response) => {
              if (response.status == 'success') {
                this.data[this.selectedIndex].ip_address = this.ip_address_edit
                this.data[this.selectedIndex].description =
                  this.description_edit
                this.selectedIsCurrentIp = false
                this.$refs.dialogEdit.close()
                this.successNotify(this.$t('message.success'))
              } else {
                Snackbar.error(response.error)
              }
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    selectInstance(instance) {
      try {
        this.selectedInstance = instance
        this.selectedIndex = this.data.findIndex((x) => x.id == instance.id)
        this.ip_address_edit = instance.ip_address
        this.description_edit = instance.description
        if (this.ip_address_edit == this.currentIp) {
          this.selectedIsCurrentIp = true
        } else {
          this.selectedIsCurrentIp = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    errorNotify(text) {
      Snackbar.error(text)
    },
    successNotify(text) {
      Snackbar.success(text)
    },
  },
}
</script>
