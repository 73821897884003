<template>
  <v-card flat height="calc(100vh - 50px)" class="pa-3">
    <input-delete-dialog
      ref="dialogDelete"
      :title="$t('message.delete')"
      :message="'この設定を削除してよろしいですか?'"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.delete')"
      @submit="
        deleteExportFormat()
        $refs.dialogDelete.close()
      "
      @cancel="$refs.dialogDelete.close()"
    />

    <v-expand-transition>
      <v-container
        v-show="dialog_export_setting_edit"
        fluid
        px-0
        pt-0
        style="
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 6;
          height: calc(100vh - 50px);
        "
      >
        <export-settings-edit
          ref="dialogExportSettingsEdit"
          :addMode="addMode"
          :sampleData="sampleData"
          :export_setting="export_setting"
          :export_format_objects="export_format_objects"
          :export_type_objects="export_type_objects"
          :export_field_objects="export_field_objects"
          :date_format_objects="date_format_objects"
          :customFormatErrors="customFormatErrors"
          :createExportFormat="createExportFormat"
          :updateExportFormat="updateExportFormat"
          :headerRowNums="headerRowNums"
          :getExportFormatObjects="getExportFormatObjects"
          :requestUpdateDisplaySettings="requestUpdateDisplaySettings"
          :edit="edit"
          @onClick="closeEditDialog()"
        />
      </v-container>
    </v-expand-transition>

    <base-dialog
      ref="dialogMultipleEdit"
      :title="$t('message.display_settings')"
      :message="$t('message.export_settings_change_display_setting')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.submit')"
      @submit="
        multipleUpdateDisplaySettings()
        $refs.dialogMultipleEdit.close()
      "
    >
      <template #card-content>
        <v-form ref="multipleEditFormRef">
          <v-radio-group v-model="multipleEditForm.display" row>
            <v-radio color="blue" label="表示" :value="true" />
            <v-radio color="blue" label="非表示" :value="false" />
          </v-radio-group>
        </v-form>
      </template>
    </base-dialog>

    <app-section-loader :status="loading_page" />

    <v-card-title>
      <v-btn color="primary" dark @click.stop="openEditDialog(null, true)">
        <s-icon icon="feather-plus" size="lg" class="tw-mr-1" />
        {{ $t('message.add') }}
      </v-btn>

      <v-btn class="ml-2" @click.native.stop="openMultipleEditDialog()">
        {{ $t('message.export_settings_multiple_edit') }}
      </v-btn>

      <v-spacer />
      <v-text-field
        v-model="search"
        placeholder="検索"
        filled
        dense
        rounded
        clearable
        hide-details
        clear-icon="feather-x-circle"
      />
    </v-card-title>
    <v-card-text>
      <baseAlert>
        連携先の会計システムのバージョンや運用方法によって実際にインポート可能なフォーマットが異なる場合があります。<br />
        その際は標準フォーマットをコピーして御社用に変更ください。
      </baseAlert>
    </v-card-text>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="export_format_objects"
      :search="search"
      item-key="id"
      class="mx-4"
      :footer-props="footerProps"
      fixed-header
      height="calc(100vh - 300px)"
      @pagination="onPagination($event)"
    >
      <template #header.selected="{}">
        <select-all-header-cell
          :items="export_format_objects"
          :all-selected="allSelected"
          :indeterminate="indeterminate"
          @click:item="onClickSelectAll($event.value, $event.message)"
        />
      </template>

      <template #item="{ item, isSelected }">
        <tr>
          <td>
            <v-simple-checkbox :value="isSelected" @input="toggleOne(item)" />
          </td>
          <td>
            <v-layout row class="ma-0 px-2">
              <div
                class="blue--text has-link"
                @click.stop="openEditDialog(item)"
              >
                {{ item.name }}
              </div>
              <v-spacer />
              <s-chip v-if="!item.organization" color="green-500" class="px-3">
                デフォルト
              </s-chip>
            </v-layout>
          </td>

          <td>
            <div class="d-flex justify-space-around">
              <s-chip v-if="item.display" color="blue-500" class="px-3">
                表示
              </s-chip>
              <s-chip v-if="!item.display" color="red-500" class="px-2">
                非表示
              </s-chip>
            </div>
          </td>

          <td>
            <div class="d-flex justify-space-around">
              <v-btn
                small
                rounded
                color="primary"
                @click.stop="openEditDialog(item, true, true)"
              >
                複製
              </v-btn>
            </div>
          </td>

          <td>
            <div class="d-flex justify-space-around">
              <v-btn
                small
                rounded
                color=""
                v-bind="{ disabled: !item.organization }"
                @click.stop="openDeleteDialog(item)"
              >
                {{ $t('message.table_delete_btn') }}
              </v-btn>
            </div>
          </td>
        </tr>
      </template>

      <template v-if="!loading_page" #no-data>
        <v-alert :value="true" color="yellow" icon="warning" class="mt-3">
          {{ $t('message.noResult') }}
        </v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ExportSettingsEdit from './components/ExportSettingsEdit'
import SelectAllHeaderCell from 'Components/Table/SelectAllHeaderCell'
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

export default {
  components: {
    ExportSettingsEdit,
    SelectAllHeaderCell,
  },
  data() {
    return {
      sampleData: {},
      edit: { flag: false, count: 0 },
      addMode: { flag: false, duplicate_flag: false },
      export_setting: {
        export_format: {
          id: null,
          header_row_size: 1,
          footer_row_size: 0,
          name: null,
          export_type: {
            id: null,
            name: null,
          },
        },
        export_type: {
          id: null,
          name: null,
        },
        layout_data: [],
        displaySetting: true,
      },
      currentDisplaySetting: true,
      get_layout_count: 0,
      export_format_objects: [],
      export_type_objects: [],
      export_field_objects: [],
      date_format_objects: [],
      customFormatErrors: [],
      headers: [
        { text: '', value: 'selected', key: 'selected', sortable: false },
        { text: 'エクスポート設定名称', value: 'name', width: 350 },
        {
          text: '表示設定',
          value: 'display',
          sortable: false,
          width: 80,
          align: 'center',
        },
        { text: '', value: 'action_duplicate', sortable: false, width: 80 },
        { text: '', value: 'action_delete', sortable: false, width: 80 },
      ],
      footerProps: {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [200, 500, 1000],
      },
      search: '',
      loading_page: false,
      input_DELETE: '',
      dialog_export_setting_edit: false,
      selected: [],
      multipleEditForm: { display: true },
      pageData: {
        page: 1,
        pageStart: 0,
        pageStop: 0,
      },
    }
  },
  computed: {
    headerRowNums: function () {
      let ids = []
      for (
        var i = 0;
        i < this.export_setting.export_format.header_row_size;
        i++
      ) {
        ids.push(i)
      }
      return ids
    },
    allSelected: function () {
      return this.export_format_objects.length == this.selected.length
    },
    indeterminate: function () {
      return !this.allSelected && this.selected.length > 0
    },
  },
  watch: {
    'edit.count': function () {
      this.edit.flag = true
      this.getSampleData()
    },
    get_layout_count: function () {
      this.getSampleData()
    },
    'export_setting.export_type.id': function (id) {
      try {
        this.getExportFieldObjects()
        if (this.addMode.flag && !this.addMode.duplicate_flag && id != null) {
          this.export_setting.layout_data = [
            {
              header_name: ['クリックで設定', '', '', '', ''],
              header_row_size: 1,
              footer_name: ['', '', '', '', ''],
              footer_row_size: 0,
              date_format_id: null,
              text_format: '',
              export_format_id: null,
              export_format_field_id: null,
              export_column_type: 0,
              header_type: 0,
              fix_value: '',
              show_all: true,
            },
          ]
          this.getSampleData()
        }
        this.addMode.duplicate_flag = false
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted() {
    this.getExportTypeObjects()
    this.getExportFormatObjects()
    this.getDateFormatObjects()
  },
  methods: {
    initExportSetting() {
      this.export_setting = {
        export_format: {
          id: null,
          name: null,
          header_row_size: 1,
          footer_row_size: 0,
          export_type: {
            id: null,
            name: null,
          },
          organization: null,
        },
        export_type: {
          id: null,
          name: null,
        },
        layout_data: [
          {
            header_name: ['クリックで設定', '', '', '', ''],
            footer_name: [],
            date_format_id: null,
            text_format: null,
            export_format_id: null,
            export_format_field_id: null,
            export_column_type: 0,
            header_type: 0,
            fix_value: '',
            show_all: true,
          },
        ],
        displaySetting: true,
      }
      this.export_field_objects = []
      this.sampleData = {}
    },
    initExportSetting_2() {
      this.export_setting = {
        export_format: {
          id: null,
          name: null,
          header_row_size: 1,
          footer_row_size: 0,
          export_type: {
            id: null,
            name: null,
          },
          organization: null,
        },
        export_type: {
          id: null,
          name: null,
        },
        layout_data: [],
        displaySetting: true,
      }
      this.export_field_objects = []
      this.sampleData = {}
    },
    initExportFormat() {
      this.export_setting.export_format = {
        id: null,
        header_row_size: this.export_setting.export_format.header_row_size,
        footer_row_size: this.export_setting.export_format.footer_row_size,
        name: null,
        export_type: {
          id: null,
          name: null,
        },
        organization: null,
      }
    },
    getExportLayoutData() {
      try {
        let url = this.$store.getters.apiExportFormatInstanceUrl
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        const auth_token = this.$store.getters.getAuthToken

        Axios.get(url, {
          headers: { Authorization: auth_token },
          params: { export_format_id: this.export_setting.export_format.id },
        }).then((response) => {
          if (response.data.status === 'error') {
            Snackbar.error(response.data.error)
            this.loading = false
          } else {
            this.export_setting.layout_data = response.data.layout_data
            this.get_layout_count += 1
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getExportFormatObjects() {
      try {
        this.loading_page = true
        let url = this.$store.getters.apiExportFormatListUrl
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        const auth_token = this.$store.getters.getAuthToken
        Axios.get(url, {
          headers: { Authorization: auth_token },
          params: { organization_id: this.$store.getters.getOrganizationID },
        }).then((response) => {
          if (response.data.status === 'error') {
            Snackbar.error(response.data.error)
          } else {
            this.export_format_objects = response.data.data
            this.loading_page = false
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getDateFormatObjects() {
      try {
        let url = this.$store.getters.apiExportDateFormatListUrl
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        const auth_token = this.$store.getters.getAuthToken
        Axios.get(url, {
          headers: { Authorization: auth_token },
        }).then((response) => {
          if (response.data.status === 'error') {
            Snackbar.error(response.data.error)
          } else {
            this.date_format_objects = response.data.data
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getExportTypeObjects() {
      try {
        let url = this.$store.getters.apiExportTypeListUrl
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        const auth_token = this.$store.getters.getAuthToken
        Axios.get(url, {
          headers: { Authorization: auth_token },
        }).then((response) => {
          if (response.data.status === 'error') {
            Snackbar.error(response.data.error)
          } else {
            this.export_type_objects = response.data.data
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getExportFieldObjects() {
      try {
        if (!this.export_setting.export_type.id) {
          return false
        }
        let url = this.$store.getters.apiExportFormatFieldListUrl
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        Axios.get(url, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { export_type_id: this.export_setting.export_type.id },
        }).then((response) => {
          if (response.data.status === 'error') {
            Snackbar.error(response.data.error)
          } else {
            this.export_field_objects = response.data.data
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    deleteExportFormat() {
      try {
        let url = this.$store.getters.apiExportFormatInstanceUrl
        let params = {
          export_format_id: this.export_setting.export_format.id,
          organization_id: this.$store.getters.getOrganizationID,
        }
        Axios.delete(url, {
          data: params,
          headers: { Authorization: this.$store.getters.getAuthToken },
        }).then((response) => {
          if (response.data.status == 'success') {
            Snackbar.success(this.$t('message.success'))
            this.initExportSetting_2()
            this.getExportFormatObjects()
            this.edit.flag = false
          } else {
            console.log(response.data.error)
            Snackbar.error(response.data.error)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async createExportFormat() {
      try {
        let url = this.$store.getters.apiExportFormatInstanceUrl
        let params = {
          export_setting: this.export_setting,
          user_organization_id: this.$store.getters.getUserOrganizationID,
          organization_id: this.$store.getters.getOrganizationID,
        }
        const response = await Axios.put(url, params, {
          headers: { Authorization: this.$store.getters.getAuthToken },
        })
        if (response.data.status == 'success') {
          Snackbar.success(this.$t('message.success'))
          this.addMode.flag = false
          this.export_setting.export_format = response.data.export_format
          this.edit.flag = false
        } else {
          Snackbar.error(response.data.error)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async updateExportFormat() {
      try {
        let url = this.$store.getters.apiExportFormatInstanceUrl
        let params = {
          export_setting: this.export_setting,
          user_organization_id: this.$store.getters.getUserOrganizationID,
          organization_id: this.$store.getters.getOrganizationID,
        }
        const auth_token = this.$store.getters.getAuthToken
        const response = await Axios.post(url, params, {
          headers: { Authorization: auth_token },
        })
        if (response.data.status == 'success') {
          Snackbar.success(this.$t('message.success'))
        } else {
          Snackbar.error(response.data.error)
        }
        this.edit.flag = false
      } catch (err) {
        console.log(err)
      }
    },
    getSampleData() {
      try {
        let url = this.$store.getters.apiExportSampleDataUrl
        const auth_token = this.$store.getters.getAuthToken
        let params = {
          organization_id: this.$store.getters.getOrganizationID,
          export_type_id: this.export_setting.export_type.id,
          header_row_size: this.export_setting.export_format.header_row_size,
          footer_row_size: this.export_setting.export_format.footer_row_size,
          layout_data: this.export_setting.layout_data,
        }
        Axios.post(url, params, {
          headers: { Authorization: auth_token },
        }).then((response) => {
          if (response.data.status == 'success') {
            let sampleData = {
              header: response.data.header,
              data: response.data.data,
              footer: response.data.footer,
            }
            //ヘッダのMenu展開状況を確認し、展開状態の場合はMenuの値を初期化しない
            let displayedMenu = false
            if (this.sampleData.header) {
              displayedMenu = this.sampleData.header.some(
                (x) => x.menu.display === true
              )
            }
            if (displayedMenu) {
              for (let i = 0; i < sampleData.header.length; i++) {
                sampleData.header[i].menu = this.sampleData.header[i].menu
              }
            } else {
              for (let i = 0; i < sampleData.header.length; i++) {
                sampleData.header[i].menu = {
                  display: false,
                  pos_x: 0,
                  pos_y: 0,
                }
              }
            }
            this.sampleData = Object.assign({}, sampleData)
            this.customFormatErrors = response.data.error_list
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async requestUpdateDisplaySettings(params) {
      try {
        const url = this.$store.getters.apiHiddenExportFormatUrl
        const auth_token = this.$store.getters.getAuthToken
        const response = await Axios.put(url, params, {
          headers: { Authorization: auth_token },
        })
        return response
      } catch (e) {
        console.log(e)
      }
    },
    multipleUpdateDisplaySettings() {
      try {
        let params = {
          organization_id: this.$store.getters.getOrganizationID,
          export_format_ids: this.getSelectedId(),
          display_setting: this.multipleEditForm.display,
        }
        this.requestUpdateDisplaySettings(params).then((response) => {
          if (response.data.status == 'success') {
            this.selected.forEach((obj) => {
              let formatObj = this.export_format_objects.find(
                (x) => x.id == obj.id
              )
              if (formatObj) {
                formatObj.display = params.display_setting
                Snackbar.success(this.$t('message.success'))
              }
            })
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    toggleOne(item) {
      if (this.selected.includes(item)) {
        this.selected = this.selected.filter((v) => v.id != item.id)
      } else {
        this.selected.push(item)
      }
    },
    onClickSelectAll(selectType, message) {
      const pageData = this.pageData
      //selectType  1:全選択 2:現在ページ選択 3:全解除 4:現在ページ解除
      switch (selectType) {
        case 1: {
          this.selected = []
          for (let item of this.export_format_objects) {
            this.selected.push(item)
          }
          break
        }
        case 2: {
          for (let i = pageData.pageStart; i < pageData.pageStop; i++) {
            const item = this.export_format_objects[i]
            if (!this.selected.includes(item)) {
              this.selected.push(item)
            }
          }
          break
        }
        case 3: {
          this.selected.splice(-this.selected.length)
          break
        }
        case 4: {
          for (let i = pageData.pageStart; i < pageData.pageStop; i++) {
            const item = this.export_format_objects[i]
            if (this.selected.includes(item)) {
              this.selected = this.selected.filter((v) => v.id != item.id)
            }
          }
          break
        }
      }
      Snackbar.success(message)
    },
    onPagination(event) {
      this.pageData.page = event.page
      this.pageData.pageStart = event.pageStart
      this.pageData.pageStop = event.pageStop
    },
    getSelectedId() {
      try {
        return this.selected.map((obj) => obj.id)
      } catch (e) {
        console.log(e)
      }
    },
    openEditDialog(item, addMode = false, duplicate = false) {
      try {
        if (item) {
          this.export_setting.export_format = item

          if (this.export_setting.export_format.id) {
            this.export_setting.export_type =
              this.export_setting.export_format.export_type
            this.export_setting.displaySetting =
              this.export_setting.export_format.display
            this.getExportLayoutData()
          }
          if (addMode) {
            this.initExportFormat()
          }
        } else {
          this.initExportSetting_2()
          this.getSampleData()
        }
        this.addMode.flag = addMode
        this.addMode.duplicate_flag = duplicate
        this.currentDisplaySetting = this.export_setting.export_format.display
        this.dialog_export_setting_edit = true
      } catch (e) {
        console.log(e)
      }
    },
    closeEditDialog() {
      this.initExportSetting_2()
      this.addMode.flag = false
      this.addMode.duplicate_flag = false
      this.dialog_export_setting_edit = false
      this.currentDisplaySetting = true
    },
    openDeleteDialog(item) {
      try {
        if (item) {
          this.export_setting.export_format = item
          this.$refs.dialogDelete.open()
        }
      } catch (e) {
        console.log(e)
      }
    },
    openMultipleEditDialog() {
      try {
        if (this.selected.length === 0) {
          Snackbar.error('データを選択してください。')
          return
        }
        this.multipleEditForm.display = true
        this.$refs.dialogMultipleEdit.open()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
