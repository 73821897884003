<template>
  <div>
    <dialog-edit-user-bank
      ref="dialogEditUserBank"
      @submit:update="updateUserBank($event)"
      @submit:create="createUserBank($event)"
    />

    <input-delete-dialog
      ref="dialogDelete"
      title="銀行口座情報の削除"
      message="選択した振込元銀行口座情報を削除してよろしいですか?"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.delete')"
      @submit="
        deleteUserBank()
        $refs.dialogDelete.close()
      "
    />

    <v-container fluid pa-0>
      <v-form ref="userBank" class="pa-4 mb-5" style="max-width: 680px">
        <v-card class="sweeep-dialog mt-4">
          <v-card-title>
            振込データ作成時の振込元銀行口座情報
            <s-icon
              tooltip="FBデータ作成時の振込元口座を指定します"
              class="tw-ml-2"
              icon="feather-info"
            />
          </v-card-title>
          <v-card-text>
            <v-btn color="primary" dark class="mb-2" @click="openAddDialog()">
              <s-icon icon="feather-plus" size="lg" class="tw-mr-1" />
              銀行口座を追加
            </v-btn>
            <v-divider />

            <app-section-loader :status="loading" />

            <div v-for="userBank in userBankList" :key="userBank.id">
              <v-toolbar flat>
                <v-btn small @click="openEditdDialog(userBank)"> 編集 </v-btn>
                <v-btn
                  v-show="userBankList.length > 1"
                  class="ml-2"
                  small
                  @click="opendDeleteDialog(userBank)"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-chip
                  v-if="userBank.default"
                  small
                  label
                  outlined
                  color="blue"
                  v-text="'デフォルト口座'"
                />
              </v-toolbar>
              <form-user-bank :user-bank="userBank" :readonly="true" />
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import { mapGetters } from 'vuex'
import FormUserBank from '@/views/settings/components/form/FormUserBank'
import DialogEditUserBank from '@/views/settings/components/dialog/DialogEditUserBank'

export default {
  components: {
    FormUserBank,
    DialogEditUserBank,
  },
  props: {
    journalItems: {
      type: Object,
      required: true,
    },
    bankData: {
      type: Object,
      required: true,
    },
    getUserSession: {
      type: Function,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userBankList: [],
      editItem: {},
      deleteItem: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['definition', 'bankList']),
  },
  created() {
    this.getUserBank()
  },
  methods: {
    getUserBank() {
      try {
        this.loading = true
        this.$store.dispatch('getUserBankList').then((data) => {
          this.userBankList = data
          this.loading = false
        })
      } catch (e) {
        console.log(e)
      }
    },
    openAddDialog() {
      const emptyData = {
        bank_name: '',
        bank_code: '',
        bank_branch_name: '',
        bank_branch_code: '',
        bank_account_name: '',
        bank_account_type: '',
        bank_account_number: '',
        user_code: '',
        default: false,
      }
      const showCheckbox = this.userBankList.length > 0
      this.$refs.dialogEditUserBank.open('add', emptyData, showCheckbox)
    },
    openEditdDialog(item) {
      this.editItem = item
      const showCheckbox = this.userBankList.length > 1
      this.$refs.dialogEditUserBank.open('edit', item, showCheckbox)
    },
    opendDeleteDialog(item) {
      this.deleteItem = item
      this.$refs.dialogDelete.open()
    },
    deleteUserBank() {
      try {
        const deleteId = this.deleteItem.id
        const data = {
          id: deleteId,
        }
        this.$store
          .dispatch('deleteUserBank', data)
          .then(() => {
            this.getUserBank()
            this.deleteItem = {}
            Snackbar.success(this.$t('message.success'))
          })
          .catch((error) => {
            Snackbar.error(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    updateUserBank(item) {
      try {
        const editId = this.editItem.id
        const data = {
          id: editId,
          bank_name: item.bank_name,
          bank_branch_name: item.bank_branch_name,
          bank_account_type: item.bank_account_type,
          bank_account_number: item.bank_account_number,
          bank_account_name: item.bank_account_name,
          user_code: item.user_code,
          default: item.default,
        }
        this.$store
          .dispatch('updateUserBank', data)
          .then(() => {
            this.getUserBank()
            this.editItem = {}
            this.$refs.dialogEditUserBank.close()
            Snackbar.success(this.$t('message.success'))
          })
          .catch((error) => {
            this.showError(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    createUserBank(item) {
      try {
        const data = {
          bank_name: item.bank_name,
          bank_branch_name: item.bank_branch_name,
          bank_account_type: item.bank_account_type,
          bank_account_number: item.bank_account_number,
          bank_account_name: item.bank_account_name,
          user_code: item.user_code,
          default: item.default,
        }
        this.$store
          .dispatch('createUserBank', data)
          .then(() => {
            this.getUserBank()
            this.$refs.dialogEditUserBank.close()
            Snackbar.success(this.$t('message.success'))
          })
          .catch((error) => {
            this.showError(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    showError(errorMessage) {
      const messages = {
        invalid_bank: '金融機関が不適切です。',
        invalid_branch: '支店が不適切です。',
      }
      const message = errorMessage ? messages[errorMessage] : ''
      if (message) {
        Snackbar.error(message)
      } else {
        Snackbar.error(
          'エラーが発生しました。サポートにお知らせください。' + errorMessage
        )
      }
    },
  },
}
</script>
